import { ethers } from "ethers";
import OldRewardManagerABI from "./OldRewardManagerABI.json";
import RewardManagerABI from "../ABIS/RewardManagerABI.json";
import { nodeTypeMapping } from "../Transactions";
import CONSTANTS from "../Constants.json";

function getOldRewardManagerContract(provider) {
    return new ethers.Contract(CONSTANTS.OldRewardsManagerAddress, OldRewardManagerABI, provider);
}

function getNewRewardManagerContract(provider) {
    return new ethers.Contract(CONSTANTS.RewardsManagerAddress, RewardManagerABI, provider);
}

export async function preTransact() {
    let ethereum = window.ethereum;
    await ethereum.request({ method: 'eth_requestAccounts' });
    return [new ethers.providers.Web3Provider(window.ethereum), ethereum.selectedAddress];
}

export async function getOwnedNodes() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getOldRewardManagerContract(signer);
    const data = await contract.getOwnedNodes(address);
    var parsedData: any = [];
    data.forEach(async node => {
        var tempNode = {
            name: node.name,
            id: node.id.toNumber(),
            tier: node.tier.toNumber(),
            type: node.nodeType.toNumber(),
            lockedAmount: Number(node.lockedETHS),
            timesCompounded: node.timesCompounded.toNumber(),
            lastInteract: Number(node.lastInteract)
        };
        parsedData.push(tempNode);
    });
    return parsedData;
}

export async function migrateNodes(
    names: string[],
    ids: number[],
    lastInteracts: number[],
    lockedAmounts: number[],
    nodeTypes: number[],
    tiers: number[],
    timesCompounded: number[]
) {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getNewRewardManagerContract(signer);
    return await contract.recoverExistingNodes(names, ids, lastInteracts, lockedAmounts, nodeTypes, tiers, timesCompounded);
}