import "./migrate.css";
import { Box, Button, Center, Text } from "@chakra-ui/react";
import LandingNavbar from "../Dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { getOwnedNodes, migrateNodes } from "./MigrationTransactions";
import { nodeTypeMapping } from "../Transactions";
import { useNavigate } from "react-router-dom";

export default function Migrate() {
  const [ownedNodes, setOwnedNodes] = useState([]);
  const navigate = useNavigate();

  const [names, setNames] = useState([]);
  const [ids, setIDs] = useState([]);
  const [lastInteracts, setLastInteracts] = useState([]);
  const [displayLocked, setDisplayLocked] = useState([]);
  const [nodeTypes, setNodeTypes] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [timesCompounded, setTimesCompounded] = useState([]);

  const [lockedAmounts, setLockedAmounts] = useState([]);

  useEffect(() => {
    getOwnedNodes().then((res) => {
      var retries = 5;
      const interval = setInterval(() => {
        var sorted = res.sort((a, b) => a.id - b.id);
        setOwnedNodes(sorted);
        if (res) {
          retries--;
          if (!retries) {
            console.log(sorted);
            setNames(sorted.map((x) => x.name));
            setIDs(sorted.map((x) => x.id));
            setLastInteracts(sorted.map((x) => x.lastInteract));
            setDisplayLocked(sorted.map((x) => x.lockedAmount));
            setLockedAmounts(sorted.map((x) => BigInt(x.lockedAmount)));
            setNodeTypes(sorted.map((x) => x.type));
            setTiers(sorted.map((x) => x.tier));
            setTimesCompounded(sorted.map((x) => x.timesCompounded));
            clearInterval(interval);
          }
        }
      }, 300);
    });
  }, []);

  return (
    <Box id="migrate-bg">
      <LandingNavbar />
      <Box className="migrate-center">
        <Box className="migrate-details">
          <Center fontSize="26px" color="#b4b3ff">
            Migrate your Etherstones
          </Center>
          <Center padding="5px">You are migrating the following stones:</Center>
          {names.map((node: any, i) => {
            return (
              <Center fontSize="18px" marginTop="10px" key={i}>
                {nodeTypeMapping[nodeTypes[i]]} #{ids[i]}
                <Text color="#8e1dad">&nbsp;//&nbsp;</Text>
                {displayLocked[i] / 10 ** 18} Locked ETHS
                <Text color="#8e1dad">&nbsp;//&nbsp;</Text>
                {names[i]}
              </Center>
            );
          })}
          <Center
            padding="5px"
            marginLeft="80px"
            marginRight="80px"
            marginTop="15px"
            textAlign="center"
          >
            Please verify that all your stones are on this list. If not, refresh the page. <br/>This is a
            one-time transaction. If you run into any issues, reach out to the
            team on Discord.
          </Center>
          <Button
            className="migrate-btn"
            onClick={() => {
              migrateNodes(
                names,
                ids,
                lastInteracts,
                lockedAmounts,
                nodeTypes,
                tiers,
                timesCompounded
              ).then(() => {
                navigate("/dashboard");
              });
            }}
          >
            Migrate Nodes
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
