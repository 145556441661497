import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Text,
  Center,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  FormControl,
  Input,
  FormLabel,
  ModalFooter,
  ModalContent,
  Select,
  FormErrorMessage,
  Link,
} from "@chakra-ui/react";
import "./dashboard.css";
import DashboardNavbar from "./DashboardNavbar";
import "./dashboard.css";
import { DashboardCards, VaultCards } from "./DashboardComponents/CardBoxes";
import { PlusIcon, RefreshIcon } from "../assets/Icons";
import { EtherVault } from "./DashboardComponents/EtherVault";
import CONSTANTS from "../Constants.json";
import { Field, Form, Formik } from "formik";
import { EtherstoneCard } from "./EtherstoneCard/EtherstoneCard";
import {
  PebbleDescription,
  ShardDescription,
  StoneDescription,
  CrystalDescription,
  PebblePlaceholder,
  ShardPlaceholder,
  StonePlaceholder,
  CrystalPlaceholder,
  Description,
  windowAlert,
} from "./DashboardHelpers";
import {
  mintNode,
  getOwnedNodes,
  EtherstoneNode,
  claimAllAvailable,
  compoundAllAvailableIntoVault,
  getBalance,
  getStakedAmount,
  getClaimableAmount,
  getClaimedAmount,
  getStakeCooldown,
  mergeAllStones,
} from "../Transactions";
import Footer from "../Footer/Footer";

export default function Dashboard() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [showPebble, setShowPebble] = useState(true);
  const [showShard, setShowShard] = useState(false);
  const [showStone, setShowStone] = useState(false);
  const [showCrystal, setShowCrystal] = useState(false);

  const ref = useRef(null);

  const [showPreview, setShowPreview] = useState(false);

  const [modalHeight, setModalHeight] = useState("550px");

  function inputSelected() {
    setShowPebble(false);
    setShowShard(false);
    setShowStone(false);
    setShowCrystal(false);
    switch ((document as any).getElementById("select").value) {
      case "Pebble":
        setShowPebble(true);
        break;
      case "Shard":
        setShowShard(true);
        break;
      case "Stone":
        setShowStone(true);
        break;
      case "Crystal":
        setShowCrystal(true);
        break;
      default:
        return;
    }
  }

  function validateName(name) {
    let error;
    if (name.length < 1 || name.length > 16) {
      error = "Please enter a name between 1 and 16 characters";
    }
    return error;
  }

  function validateAmount(amount) {
    let error;
    if (amount === "" || isNaN(amount)) {
      error = "Please enter a valid number";
    }
    if (
      amount.includes(".") &&
      amount.split(".").length > 1 &&
      amount.split(".")[1].length > 5
    ) {
      error = "Please enter a number with 5 or less decimals";
    }
    return error;
  }

  function validateInputs() {
    const values = (ref as any).current.values;
    if (validateName(values.name)) {
    }
    if (validateAmount(values.amount)) {
      windowAlert();
      return false;
    }
    const amount = parseFloat(values.amount);
    var correct = true;
    switch (values.option) {
      case "Pebble":
        if (
          amount < CONSTANTS.Pebble.MinCost ||
          amount > CONSTANTS.Pebble.MaxCost
        ) {
          windowAlert("Amount entered does is not in valid range for Pebble.");
          correct = false;
        }
        break;
      case "Shard":
        if (
          amount < CONSTANTS.Shard.MinCost ||
          amount > CONSTANTS.Shard.MaxCost
        ) {
          windowAlert("Amount entered does is not in valid range for Shard.");
          correct = false;
        }
        break;
      case "Stone":
        if (
          amount < CONSTANTS.Stone.MinCost ||
          amount > CONSTANTS.Stone.MaxCost
        ) {
          windowAlert("Amount entered does is not in valid range for Stone.");
          correct = false;
        }
        break;
      case "Crystal":
        if (
          amount < CONSTANTS.Crystal.MinCost ||
          amount > CONSTANTS.Crystal.MaxCost
        ) {
          windowAlert("Amount entered does is not in valid range for Crystal.");
          correct = false;
        }
        break;
      default:
        windowAlert();
        correct = false;
        break;
    }
    return correct;
  }

  function previewEtherstone() {
    var values;
    if ((ref as any).current.values !== null) {
      values = (ref as any).current.values;
    } else {
      setShowPreview(false);
      return <></>;
    }
    if (!validateInputs()) {
      setShowPreview(false);
      return <></>;
    }

    setModalHeight("1050px");

    return (
      <Box className="etherstone-card-preview-position">
        <EtherstoneCard
          name={values.name}
          id={"??"}
          tier={0}
          type={values.option}
          lockedAmount={parseFloat(values.amount)}
          pending={0.0}
          isPreview={true}
          identifier="preview"
          lastInteract={0}
        />
      </Box>
    );
  }

  useEffect(() => {
    window.ethereum.on("accountsChanged", () => {
      window.location.reload();
    });
  }, []);

  const [showOwnedNodes, setShowOwnedNodes] = useState(false);
  const [ownedNodes, setOwnedNodes] = useState<any>([]);

  useEffect(() => {
    refreshData();
  }, []);

  function refreshOwnedEtherstones() {
    var timesRun = 0;
    var timesMax = 5;
    const interval = setInterval(() => {
      refreshData();
      timesRun++;
      if (timesRun === timesMax) {
        clearInterval(interval);
      }
    }, 2000);
  }

  function refreshData() {
    getOwnedNodes().then((res: any[]) => {
      var retries = 5;
      const interval = setInterval(() => {
        setOwnedNodes(res.sort((a, b) => a.id - b.id));
        if (res) {
          retries--;
          if (!retries) {
            clearInterval(interval);
          }
          setShowOwnedNodes(true);
          window.dispatchEvent(new Event("resize"));
        }
      }, 300);
    });
    getStakeCooldown().then((res) => {
      var retries = 3;
      const interval = setInterval(() => {
        if (res && period != Number(res) - Date.now() / 1000) {
          setPeriod(Number(res) - Date.now() / 1000);
          setShowTimer(true);
          retries--;
          if (!retries) {
            clearInterval(interval);
          }
        }
      }, 300);
    });
    getBalance().then((res) => {
      if (res != balance) {
        setBalance(res);
      }
    });
    getStakedAmount().then((res) => {
      if (res != stakedAmount) {
        setStakedAmount(res);
      }
    });
    getClaimableAmount().then((res) => {
      if (res != claimableAmount) {
        setClaimableAmount(res);
      }
    });
    getClaimedAmount().then((res) => {
      if (res != claimedAmount) {
        setClaimedAmount(res);
      }
    });
  }

  useEffect(() => {
    if (modalHeight == "1050px") {
      var preview = document.getElementById("preview");
      if (!preview) {
        setModalHeight("550px");
      }
    }

    window.onresize = () => {
      var vault = document.getElementById("ether-vault-id");
      var etherstonesCtr = document.getElementById("etherstones-ctr-id");
      if (vault && etherstonesCtr) {
        var dist;
        if (document.body.scrollWidth > 2000) {
          dist =
            window.pageYOffset + etherstonesCtr.getBoundingClientRect().top;
          increaseHeight(dist + etherstonesCtr.clientHeight + 400);
        } else {
          dist = window.pageYOffset + vault.getBoundingClientRect().top;
          increaseHeight(dist + vault.clientHeight + 400);
        }
      }
    };
  });

  const targetRef = useRef<HTMLInputElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [initVal, setInitVal] = useState(0);
  const [height, setHeight] = useState({});

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: document.documentElement.clientHeight + 400,
      });
      setHeight({
        height: document.documentElement.offsetHeight + 400 + "px",
      });
    }
  }, []);

  const increaseHeight = (amount) => {
    setDimensions({
      //@ts-ignore
      width: targetRef.current.offsetWidth,
      height: document.body.scrollHeight,
    });
    setHeight({
      height: amount + "px",
    });
    setInitVal(0);
  };

  const [balance, setBalance] = useState("");
  const [stakedAmount, setStakedAmount] = useState("...");
  const [claimedAmount, setClaimedAmount] = useState("...");
  const [claimableAmount, setClaimableAmount] = useState("...");

  const [period, setPeriod] = useState(0);
  const [showTimer, setShowTimer] = useState(false);

  const {
    isOpen: isMergeOpen,
    onOpen: onMergeOpen,
    onClose: onMergeClose,
  } = useDisclosure();

  return (
    <div id="bg" ref={targetRef} style={height}>
      <DashboardNavbar />
      <div className="content">
        <Center className="header top-of-page">Dashboard</Center>
        <DashboardCards />
        <Center className="header">Vault Statistics</Center>
        <VaultCards />
        <div className="bottom">
          <div className="left">
            <Box className="left-header">
              <Box className="title">My Etherstones</Box>
              <br />
              <Text marginTop="5px" marginBottom="5px">
                Create, manage, and view your Etherstones. Etherstones are nodes
                that reward ETHS every 48 hours. These rewards can be compounded
                into the Ether Vault for a reduced tax and compound bonus (18%
                tax), or directly claimed (28% tax). To learn more, please read
                our whitepaper.
              </Text>
              <Button
                color="black"
                margin="5px"
                onClick={() => compoundAllAvailableIntoVault()}
              >
                Compound All Into Vault
              </Button>
              <Button
                color="black"
                margin="5px"
                onClick={() => claimAllAvailable()}
              >
                Claim All Pending
              </Button>
              <Button color="black" margin="5px" onClick={onOpen}>
                <PlusIcon />
                &nbsp;Create a Etherstone
              </Button>
              <Button color="black" margin="5px" onClick={onMergeOpen}>
                Merge All Stones
              </Button>
              <Button
                borderRadius="50%"
                maxW="50px"
                margin="5px"
                title="Refresh"
                onClick={() => refreshOwnedEtherstones()}
              >
                <Box position="absolute">
                  <RefreshIcon />
                </Box>
              </Button>
              <div id="create-modal">
                <Modal
                  isOpen={isOpen}
                  onClose={onClose}
                  closeOnEsc={false}
                  closeOnOverlayClick={false}
                >
                  <Formik
                    innerRef={ref}
                    initialValues={{ option: "Pebble", name: "", amount: "" }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={async (values, actions) => {
                      validateInputs();
                      mintNode(values.name, values.amount, values.option).then(
                        () => {
                          actions.resetForm();
                          refreshOwnedEtherstones();
                          onClose();
                        }
                      );
                    }}
                  >
                    {({ isSubmitting, resetForm }) => (
                      <Form>
                        <ModalOverlay />
                        <ModalContent
                          bgColor="rgba(22, 12, 34)"
                          color="#E5DFD8"
                          border="1px solid white"
                          height={modalHeight}
                          maxW="600px"
                          id="modal-content"
                        >
                          <ModalHeader fontFamily="Gotham" fontWeight="500">
                            Summon your Etherstone
                          </ModalHeader>
                          <ModalBody pb={8}>
                            <Field name="option">
                              {({ field, form }) => (
                                <FormControl mt={4}>
                                  <FormLabel>Select Etherstone</FormLabel>
                                  <Center
                                    onChange={() => {
                                      inputSelected();
                                    }}
                                  >
                                    <Select
                                      color="black"
                                      backgroundColor="whiteAlpha.900"
                                      id="select"
                                      fontFamily="Gotham"
                                      fontWeight="500"
                                      {...field}
                                    >
                                      <option value="Pebble">
                                        Pebble (Minimum:{" "}
                                        {CONSTANTS.Pebble.MinCost} ETHS)
                                      </option>
                                      <option value="Shard">
                                        Shard (Minimum:{" "}
                                        {CONSTANTS.Shard.MinCost} ETHS)
                                      </option>
                                      <option value="Stone">
                                        Stone (Minimum:{" "}
                                        {CONSTANTS.Stone.MinCost} ETHS)
                                      </option>
                                      <option value="Crystal">
                                        Crystal (Minimum:{" "}
                                        {CONSTANTS.Crystal.MinCost} ETHS)
                                      </option>
                                    </Select>
                                  </Center>
                                </FormControl>
                              )}
                            </Field>
                            <br />
                            <Field name="name" validate={validateName}>
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.name && form.touched.name
                                  }
                                >
                                  <FormLabel>Name your Etherstone</FormLabel>
                                  <Input
                                    color="black"
                                    backgroundColor="whiteAlpha.900"
                                    placeholder="My First Etherstone"
                                    fontWeight="500"
                                    _placeholder={{ color: "#6e6e6e" }}
                                    maxLength="16"
                                    {...field}
                                  />
                                  {!form.errors.name ? (
                                    <Description
                                      content={
                                        "Enter a name between 1 and 16 characters"
                                      }
                                    />
                                  ) : null}
                                  <FormErrorMessage>
                                    {form.errors.name}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <br />
                            <Field name="amount" validate={validateAmount}>
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.amount && form.touched.amount
                                  }
                                >
                                  <FormLabel>Lock $ETHS</FormLabel>
                                  {showPebble ? (
                                    <>
                                      <Input
                                        color="black"
                                        backgroundColor="whiteAlpha.900"
                                        placeholder={PebblePlaceholder}
                                        fontWeight="500"
                                        _placeholder={{ color: "#6e6e6e" }}
                                        {...field}
                                      />
                                      {!form.errors.amount ? (
                                        <Description
                                          content={PebbleDescription}
                                        />
                                      ) : null}
                                      <FormErrorMessage>
                                        {form.errors.amount}
                                      </FormErrorMessage>
                                    </>
                                  ) : null}
                                  {showShard ? (
                                    <>
                                      <Input
                                        color="black"
                                        backgroundColor="whiteAlpha.900"
                                        placeholder={ShardPlaceholder}
                                        fontWeight="500"
                                        _placeholder={{ color: "#6e6e6e" }}
                                        {...field}
                                      />
                                      {!form.errors.amount ? (
                                        <Description
                                          content={ShardDescription}
                                        />
                                      ) : null}
                                      <FormErrorMessage>
                                        {form.errors.amount}
                                      </FormErrorMessage>
                                    </>
                                  ) : null}
                                  {showStone ? (
                                    <>
                                      <Input
                                        color="black"
                                        backgroundColor="whiteAlpha.900"
                                        placeholder={StonePlaceholder}
                                        fontWeight="500"
                                        _placeholder={{ color: "#6e6e6e" }}
                                        {...field}
                                      />
                                      {!form.errors.amount ? (
                                        <Description
                                          content={StoneDescription}
                                        />
                                      ) : null}
                                      <FormErrorMessage>
                                        {form.errors.amount}
                                      </FormErrorMessage>
                                    </>
                                  ) : null}
                                  {showCrystal ? (
                                    <>
                                      <Input
                                        color="black"
                                        backgroundColor="whiteAlpha.900"
                                        placeholder={CrystalPlaceholder}
                                        fontWeight="500"
                                        _placeholder={{ color: "#6e6e6e" }}
                                        {...field}
                                      />
                                      {!form.errors.amount ? (
                                        <Description
                                          content={CrystalDescription}
                                        />
                                      ) : null}
                                      <FormErrorMessage>
                                        {form.errors.amount}
                                      </FormErrorMessage>
                                    </>
                                  ) : null}
                                </FormControl>
                              )}
                            </Field>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              bgColor="#af79c7 !important"
                              _hover={{ bg: "#d197ce !important" }}
                              color="black"
                              type="submit"
                              isLoading={isSubmitting}
                              mr={3}
                            >
                              Summon Etherstone
                            </Button>
                            {!showPreview ? (
                              <Button
                                bgColor="#af79c7 !important"
                                _hover={{ bg: "#d197ce !important" }}
                                color="black"
                                mr={3}
                                onClick={() => setShowPreview(true)}
                              >
                                Preview
                              </Button>
                            ) : null}
                            <Button
                              bgColor="#adadad"
                              color="black"
                              onClick={() => {
                                onClose();
                                setShowPreview(false);
                                resetForm();
                                setShowPebble(true);
                                setShowShard(false);
                                setShowStone(false);
                                setShowCrystal(false);
                              }}
                            >
                              Cancel
                            </Button>
                            {showPreview ? previewEtherstone() : null}
                          </ModalFooter>
                        </ModalContent>
                      </Form>
                    )}
                  </Formik>
                </Modal>
              </div>
              <Box
                className="etherstones-ctr"
                marginTop="5px"
                id="etherstones-ctr-id"
              >
                {showOwnedNodes
                  ? ownedNodes.map((node: any, i) => {
                      return (
                        <Box key={i}>
                          <EtherstoneCard
                            id={node.id}
                            name={node.name}
                            type={node.type}
                            lockedAmount={node.lockedAmount}
                            timesCompounded={node.timesCompounded}
                            pending={node.pending}
                            tier={node.tier}
                            lastInteract={node.lastInteract}
                          />
                        </Box>
                      );
                    })
                  : null}
                {showOwnedNodes && ownedNodes.length == 0 ? (
                  <Center position="absolute" marginTop="200px" fontSize="20px">
                    Create an Etherstone or <Link href="https://etherstones.fi/migrate" color="#b4b3ff">&nbsp;Migrate your existing
                    Etherstones&nbsp;</Link> to our new contract.
                  </Center>
                ) : null}
              </Box>
            </Box>
          </div>

          <div className="right">
            <EtherVault
              balance={balance}
              claimedAmount={claimedAmount}
              stakedAmount={stakedAmount}
              claimableAmount={claimableAmount}
              period={period}
              showTimer={showTimer}
            />
          </div>

          <Modal isOpen={isMergeOpen} onClose={onMergeClose}>
            <ModalOverlay />
            <ModalContent
              color="#E5DFD8"
              border="1px solid white"
              bgColor="rgba(22, 12, 34)"
              marginTop="400px"
            >
              <ModalHeader>
                <Center>Merge your Etherstones</Center>
              </ModalHeader>
              <Center
                color="red.300"
                marginLeft="30px"
                marginRight="30px"
                marginBottom="10px"
              >
                Merging will erase all pending rewards from your Etherstones.
                Please claim or compound all before merging.
              </Center>
              <Center marginLeft="30px" marginRight="30px" marginBottom="10px">
                Merge two or more Etherstones into a single Etherstone. The new
                Etherstone's locked amount will be the sum of all merged
                amounts, and the highest compound bonus among merged stones is
                retained. By merging, you are also able increase your stone's
                tier. There is no locked ETHS cap when merging.
              </Center>
              <ModalFooter>
                <Button
                  color="black"
                  bgColor="#af79c7 !important"
                  _hover={{ bg: "#d197ce !important" }}
                  type="submit"
                  mr={3}
                  onClick={() => mergeAllStones()}
                >
                  Merge
                </Button>
                <Button
                  color="black"
                  onClick={() => {
                    onMergeClose();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      </div>
      <Box height="200px" w="100%"></Box>
      <Footer />
    </div>
  );
}
