import CONSTANTS from "../../Constants.json";
import React, { useEffect, useState, useRef } from "react";
import "./etherstonecard.css";
import pebbles from "../../assets/etherstones/pebbles";
import shards from "../../assets/etherstones/shards";
import stones from "../../assets/etherstones/stones";
import crystals from "../../assets/etherstones/crystals";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  claimEtherstoneReward,
  compoundEtherstoneReward,
} from "../../Transactions";

export type EtherstoneProps = {
  name: String; // name of the etherstone
  id: number | string; // number ID
  tier: number; // 0: emerald, 1: sapphire, 2: amethyst, 3: ruby, 4: radiant
  type: String; // Pebble, Shard, Stone, or Crystal
  lockedAmount: Number; // locked ETHS
  pending: Number; // pending rewards to claim
  isPreview?;
  identifier?: string;
  timesCompounded?: number;
  lastInteract: Number;
};

export function convertToHHMMSS(secs) {
  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
}

export function EtherstoneCard(props: EtherstoneProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const videos = document.querySelectorAll("video");
    videos.forEach((element) => {
      element.playbackRate = 0.5;
    });
    if (videoRef && videoRef.current) {
      videoRef.current.defaultMuted = true;
      videoRef.current.controls = false;
      videoRef.current.muted = true;
      videoRef.current.autoplay = true;
      videoRef.current.playsInline = true;
      videoRef.current.setAttribute("muted", "");

      setTimeout(() => {
        const promise = videoRef.current?.play();
      }, 0);
    }
  });

  const hoverClasses = [
    "hover-color-emerald",
    "hover-color-sapphire",
    "hover-color-amethyst",
    "hover-color-ruby",
    "hover-color-radiant",
  ];
  const hoverClass = hoverClasses[props.tier];

  const tierMapping = ["Emerald", "Sapphire", "Amethyst", "Ruby", "Radiant"];

  var videoToPlay;
  var baseDailyReward;
  var compoundBonus;

  if (props.type === "Pebble") {
    videoToPlay = pebbles[props.tier];
    baseDailyReward = CONSTANTS.Pebble.DailyReturn;
    compoundBonus = props.timesCompounded
      ? props.timesCompounded * CONSTANTS.Pebble.CompoundBonus
      : 0;
  } else if (props.type === "Shard") {
    videoToPlay = shards[props.tier];
    baseDailyReward = CONSTANTS.Shard.DailyReturn;
    compoundBonus = props.timesCompounded
      ? props.timesCompounded * CONSTANTS.Shard.CompoundBonus
      : 0;
  } else if (props.type === "Stone") {
    videoToPlay = stones[props.tier];
    baseDailyReward = CONSTANTS.Stone.DailyReturn;
    compoundBonus = props.timesCompounded
      ? props.timesCompounded * CONSTANTS.Stone.CompoundBonus
      : 0;
  } else if (props.type === "Crystal") {
    videoToPlay = crystals[props.tier];
    baseDailyReward = CONSTANTS.Crystal.DailyReturn;
    compoundBonus = props.timesCompounded
      ? props.timesCompounded * CONSTANTS.Crystal.CompoundBonus
      : 0;
  }
  compoundBonus *= CONSTANTS.timesCompoundedBoosts[props.tier];
  compoundBonus = parseFloat(compoundBonus.toFixed(5));

  var compoundBonusClass = "stats-description-right ";
  if (compoundBonus.toString().length == 7) {
    compoundBonusClass += "smallest-font-size";
  } else if (compoundBonus.toString().length == 6) {
    compoundBonusClass += "reduced-font-size";
  }

  var timeElapsed = parseInt(
    (Date.now() / 1000 - parseInt(props.lastInteract.toString())).toString()
  );

  const [showCooldown, setShowCooldown] = useState(false);
  const [cooldown, setCooldown] = useState(
    CONSTANTS.NodeCooldown - timeElapsed
  );

  useEffect(() => {
    if (timeElapsed > CONSTANTS.NodeCooldown) {
      setShowCooldown(false);
      return;
    }
    setShowCooldown(true);
    const timerInterval = setInterval(() => {
      setCooldown(cooldown - 1);
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [cooldown]);

  useEffect(() => {
    setCooldown(CONSTANTS.NodeCooldown - timeElapsed);
  }, [timeElapsed]);

  var isDisabled = props.isPreview ? true : false;
  var etherstoneId = props.identifier ? props.identifier : undefined;

  const cooldownClass = showCooldown ? "cooldown-button" : "";

  var label = props.timesCompounded + " ";
  if(props.timesCompounded && props.timesCompounded === 1) {
    label += "Time Compounded";
  } else {
    label += "Times Compounded"
  }

  return (
    <Tooltip label={label} placement="top" bg="#742d70" fontSize="16px">
      <Box className="etherstone-ctr" id={etherstoneId}>
        <div className={hoverClass}>
          <div className="overlay">
            <div className="overlay-bg">
              <div className="overlay-element top-left"></div>
              <div className="overlay-element top-right"></div>
              <div className="overlay-element bottom-left"></div>
              <div className="overlay-element bottom-right"></div>
              <div className="etherstone-card-title">{props.name}</div>
              <div className="locked-eths">
                Locked Amount
                <div className="stats-description-left">
                  {props.lockedAmount} ETHS
                </div>
              </div>
              <div className="daily-compound-reward">
                Daily Reward
                <div className={compoundBonusClass}>
                  {baseDailyReward}% + {compoundBonus}%
                </div>
              </div>
              <div className="etherstone-card-footer">
                {tierMapping[props.tier] + " " + props.type}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="dot"></span>
                &nbsp;&nbsp;Etherstone #{props.id}
              </div>
            </div>
            <div className="pending-reward">Pending: {props.pending} ETHS</div>
            <div className="etherstone-button-ctr">
              <button
                className={cooldownClass + " claim-etherstone-btn"}
                disabled={isDisabled}
                onClick={() => {
                  claimEtherstoneReward(parseInt(props.id.toString()));
                }}
              >
                {showCooldown ? "Cooldown:" : "Claim Pending"}
              </button>
              <button
                className={
                  cooldownClass +
                  "-right compound-etherstone-btn etherstone-right"
                }
                disabled={isDisabled}
                onClick={() =>
                  compoundEtherstoneReward(parseInt(props.id.toString()))
                }
              >
                {showCooldown ? convertToHHMMSS(cooldown) : "Compound Pending"}
              </button>
            </div>
            <div className="etherstone-vid-ctr">
              <div
                dangerouslySetInnerHTML={{
                  __html: `<video className="large-video" ref=${videoRef} muted loop autoPlay playsInline src=${videoToPlay}></video>`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </Box>
    </Tooltip>
  );
}
