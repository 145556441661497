import emerald from "./pebble_emerald.webm";
import sapphire from "./pebble_sapphire.webm";
import amethyst from "./pebble_amethyst.webm";
import ruby from "./pebble_ruby.webm";
import radiant from "./pebble_radiant.webm";

const pebbles = [
    emerald,
    sapphire,
    amethyst,
    ruby,
    radiant
]

export default pebbles;