import { useEffect, useState } from "react";
import { DollarSymbolIcon, EthereumIcon, ETHSIcon, GemIcon, LockIcon, MoneyUpIcon, PebblesIcon, PeopleIcon, SafeIcon, ShardIcon, StonesIcon, TotalStonesIcon } from "../../assets/Icons";
import {
  getDailyNodeEmissions,
  getDashboardStatistics,
  getVaultStatistics,
} from "../../Transactions";

function Card(props) {
  return (
    <div className="card">
      <div>
        <div className="iconBx">{props.icon}</div>
        <div className="numbers">{props.text}</div>
        <div className="cardName">{props.description}</div>
      </div>
    </div>
  );
}

export function DashboardCards() {
  const [price, setPrice] = useState("...");
  const [numPebbles, setNumPebbles] = useState("...");
  const [numShards, setNumShards] = useState("...");
  const [numStones, setNumStones] = useState("...");
  const [numCrystals, setNumCrystals] = useState("...");
  const [numTotal, setNumTotal] = useState("...");
  const [circulatingSupply, setCirculatingSupply] = useState("...");
  const [totalLocked, setTotalLocked] = useState("...");
  const [dailyNodeEmissions, setDailyNodeEmissions] = useState("...");
  
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDashboardStatistics().then((data) => {
        setPrice(data["price"]);
        setNumPebbles(data["numPebbles"]);
        setNumShards(data["numShards"]);
        setNumStones(data["numStones"]);
        setNumCrystals(data["numCrystals"]);
        setNumTotal(data["numTotal"]);
        setCirculatingSupply(data["circulatingSupply"]);
        setTotalLocked(data["totalLocked"]);
      });
      getDailyNodeEmissions().then((res) => {
        setDailyNodeEmissions(res);
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  

  return (
    <div className="cardBox">
      <Card
        text={"" + price}
        description={"Current Price"}
        icon={<DollarSymbolIcon />}
      />
      <Card
        text={circulatingSupply + " ETHS"}
        description={"Circulating Supply"}
        icon={<ETHSIcon />}
      />
      <Card
        text={totalLocked + " ETHS"}
        description={"Total Locked in Etherstones"}
        icon={<LockIcon />}
      />
      <Card
        text={dailyNodeEmissions + " ETHS"}
        description={"Daily Etherstone emissions"}
        icon={<ETHSIcon />}
      />
      <Card
        text={numPebbles}
        description={"Number of Pebbles"}
        icon={<PebblesIcon />}
      />
      <Card
        text={numShards}
        description={"Number of Shards"}
        icon={<ShardIcon />}
      />
      <Card
        text={numStones}
        description={"Number of Stones"}
        icon={<StonesIcon />}
      />
      <Card
        text={numCrystals}
        description={"Number of Crystals"}
        icon={<GemIcon />}
      />
      <Card
        text={numTotal}
        description={"Total Etherstones"}
        icon={<TotalStonesIcon />}
      />
    </div>
  );
}

export function VaultCards() {
  const [totalDistributed, setTotalDistributed] = useState("...");
  const [totalLockedInVault, setTotalLockedInVault] = useState("...");
  const [numShareholders, setNumShareholders] = useState("...");
  const [totalWETHCompounded, setTotalWETHCompounded] = useState("...");
  const [totalNodeCompounded, setTotalNodeCompounded] = useState("...");
  const [vaultAPR, setVaultAPR] = useState("...");
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getVaultStatistics().then((data) => {
        setTotalDistributed(data["totalDistributed"]);
        setTotalLockedInVault(data["totalLockedInVault"]);
        setNumShareholders(data["numberOfShareholders"]);
        setTotalWETHCompounded(data["totalWETHCompounded"]);
        setTotalNodeCompounded(data["totalCompoundedFromNode"]);
        setVaultAPR(data["vaultAPR"]);
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="cardBox">
      <Card
        text={totalDistributed + " ETH"}
        description={"Total ETH Distributed"}
        icon={<EthereumIcon />}
      />
      <Card
        text={totalLockedInVault + " ETHS"}
        description={"Total Staked in Vault"}
        icon={<SafeIcon />}
      />
      <Card
        text={totalNodeCompounded + " ETHS"}
        description={"Total Compounded into Vault"}
        icon={<ETHSIcon />}
      />
      <Card
        text={vaultAPR + "%"}
        description={"Current APR"}
        icon={<MoneyUpIcon />}
      />
    </div>
  );
}
