import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Landing from "./components/Landing/Landing";
import { ChakraProvider, useNumberInput } from "@chakra-ui/react";
import Dashboard from "./components/Dashboard/Dashboard";
import Presale from "./components/Presale/Presale";
import LandingNoRender from "./components/LandingNoRender/LandingNoRender";
import Option1 from "./components/Presale/Option1";
import Option2 from "./components/Presale/Option2";
import Redeem from "./components/Presale/Redeem";
import { getAddress } from "./components/ConnectAccount";
import CONSTANTS from "./components/Constants.json";
import { useEffect, useState } from "react";
import Migrate from "./components/Migrate/Migrate";
import Lottery from "./components/Lottery/Lottery";

function AppRoutes() {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/norender" element={<LandingNoRender />} />
          <Route path="/dashboard" element={true ? <Dashboard /> : <Navigate replace to="/" />} />
          <Route path="/migrate" element={<Migrate />} />
          <Route path="/lottery" element={<Lottery />} />
          <Route path="/presale" element={false ? <Presale /> : <Navigate replace to="/" />} />
          <Route path="/presale/redeem" element={false ? <Redeem /> : <Navigate replace to="/" />} />
          <Route path="/presale/option1" element={false ? <Option1 /> : <Navigate replace to="/" />} />
          <Route path="/presale/option2" element={false ? <Option2 /> : <Navigate replace to="/" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default AppRoutes;
