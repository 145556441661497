import emerald from "./crystal_emerald.webm";
import sapphire from "./crystal_sapphire.webm";
import amethyst from "./crystal_amethyst.webm";
import ruby from "./crystal_ruby.webm";
import radiant from "./crystal_radiant.webm";

const crystals = [
    emerald,
    sapphire,
    amethyst,
    ruby,
    radiant
]

export default crystals;