import { ethers } from "ethers";
import CONSTANTS from "./Constants.json";
import ETHSTokenABI from "./ABIS/ETHSTokenABI.json";
import RewardManagerABI from "./ABIS/RewardManagerABI.json"
import VaultDistributorABI from "./ABIS/VaultDistributorABI.json";

export const nodeTypeMapping = {0: "Pebble", 1: "Shard", 2: "Stone", 3: "Crystal"}
const reversedTypeMapping = {"Pebble": 0,"Shard": 1, "Stone": 2, "Crystal": 3}

function getETHSTokenContract(provider) {
    return new ethers.Contract(CONSTANTS.ETHSTokenAddress, ETHSTokenABI, provider);
}

function getRewardManagerContract(provider) {
    return new ethers.Contract(CONSTANTS.RewardsManagerAddress, RewardManagerABI, provider);
}

function getVaultDistributorContract(provider) {
    return new ethers.Contract(CONSTANTS.DistributorAddress, VaultDistributorABI, provider);
}

export async function preTransact() {
    let ethereum = window.ethereum;
    await ethereum.request({ method: 'eth_requestAccounts' });
    return [new ethers.providers.Web3Provider(window.ethereum), ethereum.selectedAddress];
}

export async function getChainId() {
    await preTransact();
    return Number(window.ethereum.chainId);
}

export async function mintNode(name: string, amount: string, type: string) {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getRewardManagerContract(signer);
    const amountToSend = BigInt(parseFloat(amount)*10**18);
    await contract.mintNode(name, amountToSend.toString(), reversedTypeMapping[type]);
}

export async function vaultDeposit(amount: string) {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getETHSTokenContract(signer);
    await contract.vaultDepositTransfer(BigInt(parseFloat(amount)*10**18));
}

export async function vaultWithdraw(amount: string) {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getETHSTokenContract(signer);
    await contract.vaultWithdrawTransfer(BigInt(parseFloat(amount)*10**18));
}

export type EtherstoneNode = {
    name: String; // name of the etherstone
    id: Number; // number ID
    tier: number; // 0: emerald, 1: sapphire, 2: amethyst, 3: ruby, 4: radiant
    type: String; // Pebble, Shard, Stone, or Crystal
    lockedAmount: String; // locked ETHS
    timesCompounded: Number; // compound bonus
    pending: String; // pending rewards to claim
    lastInteract: Number; // timestamp
  };
  

export async function getOwnedNodes() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getRewardManagerContract(signer);
    const data = await contract.getOwnedNodes(address);
    var parsedData: any = [];
    data.forEach(async node => {
        var tempNode = {
            name: node.name,
            id: node.id.toNumber(),
            tier: node.tier.toNumber(),
            type: nodeTypeMapping[node.nodeType.toNumber()],
            lockedAmount: Number(node.lockedETHS / 10**18).toString(),
            timesCompounded: node.timesCompounded.toNumber(),
            pending: (await contract.calculateRewards(node.id.toNumber())/10**18).toFixed(4),
            lastInteract: Number(node.lastInteract)
        };
        parsedData.push(tempNode);
    });
    return parsedData;
}

export async function getBalance() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getETHSTokenContract(signer);
    return String(Number(await contract.balanceOf(address)/10**18));
}

export async function getStakedAmount() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getVaultDistributorContract(signer);
    const data = await contract.shares(address);
    return String(Number(data[0]._hex)/(10**18));
}

export async function getClaimableAmount() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getVaultDistributorContract(signer);
    const data = await contract.getUnpaidEarnings(address);
    return (Number(data)/(10**18)).toString();
}

export async function getClaimedAmount() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getVaultDistributorContract(signer);
    const data = await contract.shares(address);
    return (Number(data[2]._hex)/(10**18)).toString();
}

export async function getStakeCooldown() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getETHSTokenContract(signer);
    const data = await contract.getPersonalVaultCooldown();
    return data;
}

export async function claimDividend(action: number) {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getVaultDistributorContract(signer);
    await contract.claimDividend(action);
}

export async function compoundAllAvailableIntoVault() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getRewardManagerContract(signer);
    await contract.compoundAllAvailableEtherstoneReward();
}

export async function claimAllAvailable() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getRewardManagerContract(signer);
    await contract.claimAllAvailableEtherstoneReward();
}

export async function mergeAllStones() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getRewardManagerContract(signer);
    await contract.mergeAllNodes();
}

export async function claimEtherstoneReward(id: number) {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getRewardManagerContract(signer);
    await contract.claimEtherstoneReward(id);
}

export async function compoundEtherstoneReward(id: number) {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getRewardManagerContract(signer);
    await contract.compoundEtherstoneReward(id);
}

export async function getDashboardStatistics() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    var price = "$" + await getETHSPrice();
    const rewardManager = getRewardManagerContract(signer);
    const numPebbles = await rewardManager.totalPebbles();
    const numShards = await rewardManager.totalShards();
    const numStones = await rewardManager.totalStones();
    const numCrystals = await rewardManager.totalCrystals();
    const numTotal = await rewardManager.getNumberOfNodes();
    const eths = getETHSTokenContract(signer);
    const circulatingSupply = (await eths.getCirculatingSupply()) - (await eths.balanceOf(CONSTANTS.LotteryAddress));
    const totalLocked = Number(await eths.totalLocked()/10**18);
    const data = {
        "price": price,
        "numPebbles": Number(numPebbles).toLocaleString(),
        "numShards": Number(numShards).toLocaleString(),
        "numStones": Number(numStones).toLocaleString(),
        "numCrystals": Number(numCrystals).toLocaleString(),
        "numTotal": Number(numTotal).toLocaleString(),
        "circulatingSupply": Number(circulatingSupply/10**18).toLocaleString(),
        "totalLocked": totalLocked.toLocaleString(),
    }
    return data;
}

async function getETHSPrice() {
    return fetch("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=etherstones&order=market_cap_desc&per_page=100&page=1&sparkline=false")
    .then(res => res.json())
    .then(res => {
        return res[0].current_price.toFixed(3);
    });
}

// expensive so done in different function
export async function getDailyNodeEmissions() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const rewardManager = getRewardManagerContract(signer);
    const numNodes = await rewardManager.getNumberOfNodes();
    var dailyEmission = 0;
    for(var i = 1; i < numNodes + 1; i++) {
        dailyEmission += Number(await rewardManager.getDailyNodeEmission(i)/10**18)
    }
    return dailyEmission.toLocaleString();
}

export async function getVaultStatistics() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const distributor = getVaultDistributorContract(signer);
    const eths = getETHSTokenContract(signer);
    const totalDistributed = Number(await distributor.totalDividends()/10**18);
    const totalLockedInVault = await getTotalShares();
    const numberOfShareholders = await distributor.getNumberOfShareholders();
    const totalCompoundedFromNode = Number(await eths.totalCompoundedFromNode()/10**18);
    const vaultAPR = await getVaultAPR();
    const data = {
        "totalDistributed": totalDistributed.toLocaleString(),
        "totalLockedInVault": totalLockedInVault.toLocaleString(),
        "numberOfShareholders": numberOfShareholders.toLocaleString(),
        "totalCompoundedFromNode": totalCompoundedFromNode.toLocaleString(),
        "vaultAPR": vaultAPR
    }
    return data;
}

async function getTotalShares() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const distributor = getVaultDistributorContract(signer);
    return Number(await distributor.totalShares()/10**18);
}

async function getVaultAPR() {
    var date = new Date();
    var dayAgo = (date.setDate(date.getDate() - 1)/1000);
    return fetch("https://api.snowtrace.io/api?module=account&action=txlistinternal&address=0xca02Bf0233DfacE77Ada036b5F28ec50f902dcEF&startblock=0&endblock=99999899&sort=desc&apikey=86J1QRPM1HPBNH4T59YCEW4PB4KXNEZQEM")
    .then(res => res.json())
    .then(res => {
        res = res.result;
        var c;
        for(var i = 0; i < res.length; i++) {
            if(res[i].timeStamp < dayAgo) {
                c = i/2;
                break;
            }
        }
        const hash = res[0].hash;
        return fetch(`https://api.snowtrace.io/api?module=account&action=txlistinternal&txhash=${hash}&apikey=86J1QRPM1HPBNH4T59YCEW4PB4KXNEZQEM`)
        .then(res => res.json())
        .then(res => {
            var amountAVAX;
            res = res.result;
            for(var i = 0; i < res.length; i++) {
                if(String(res[i].to).toLowerCase() === "0xca02Bf0233DfacE77Ada036b5F28ec50f902dcEF".toLowerCase()) {
                    amountAVAX = res[i].value/(10**18);
                    break;
                }
            }
            return fetch("https://api.coingecko.com/api/v3/simple/price?ids=avalanche-2&vs_currencies=usd")
            .then(res => res.json())
            .then(async res => {
                var valueAVAX = res["avalanche-2"].usd;
                var distributedEstimate = valueAVAX * amountAVAX * c;
                var currPrice = await getETHSPrice();
                var totalShares = await getTotalShares();
                var onePercentValue = totalShares*currPrice;
                var distributedProrated = distributedEstimate*365;
                return Number((distributedProrated/onePercentValue)*100).toFixed(2);
            })

        });
    });
}