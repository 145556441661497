import emerald from "./shard_emerald.webm";
import sapphire from "./shard_sapphire.webm";
import amethyst from "./shard_amethyst.webm";
import ruby from "./shard_ruby.webm";
import radiant from "./shard_radiant.webm";

const shards = [
    emerald,
    sapphire,
    amethyst,
    ruby,
    radiant
]

export default shards;