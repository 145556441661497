import React, { useEffect, useState } from "react";
import {
  Button,
  Link,
  Center,
  Flex,
  HStack,
  VStack,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import { EtherstonesLogoSmall } from "../assets/Icons";
import {
  connectWallet,
  getAddress,
  redirectIfNotConnected,
} from "../ConnectAccount";
import { useNavigate } from "react-router-dom";

const avaxLogo = require("../assets/avax-logo.png");

export default function LandingNavbar() {
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  const [isDesktop] = useMediaQuery("(min-width: 761px)");
  useEffect(() => {
    getAddress().then((res) => setAddress(res));
    redirectIfNotConnected().then((res) => (res ? navigate("../") : null));
    // Subscribe to accounts change
    window.ethereum.on("accountsChanged", (accounts: string[]) => {
      redirectIfNotConnected().then((res) => {
        if (res) {
          navigate("../");
          window.location.reload();
        } else {
          connectWallet().then(() => {
            getAddress().then((res) => setAddress(res));
          });
        }
      });
    });

    // Subscribe to chainId change
    window.ethereum.on("chainChanged", (chainId: number) => {
      if (chainId != 4 && chainId != 0xa86a) {
        navigate("../");
        window.location.reload();
      }
    });

    // Subscribe to provider connection
    window.ethereum.on("connect", (info: { chainId: number }) => {
      console.log(info);
    });

    // Subscribe to provider disconnection
    window.ethereum.on(
      "disconnect",
      (error: { code: number; message: string }) => {
        navigate("../");
        window.location.reload();
      }
    );
  }, []);

  function Buttons() {
    return (
      <>
        <Center w="360px">
          <HStack spacing={1}>
            <Link
              href="https://traderjoexyz.com/trade?outputCurrency=0x1efE6a8AF01920912F0B4f7F26fc16e294664c4C#/"
              target="_blank"
            >
              <Button
                backgroundColor="white !important"
                color="black"
                padding="20px"
                borderRadius="5px"
                height="30px"
              >
                <img src={avaxLogo} width="25px" height="25px"></img>
                &nbsp;Get $ETHS
              </Button>
            </Link>
            <Button
              backgroundColor="white !important"
              color="black"
              padding="20px"
              borderRadius="5px"
              height="30px"
              onClick={() => navigate("/lottery")}
            >
              Lottery
            </Button>
            <Button
              backgroundColor="white !important"
              color="black"
              padding="20px"
              borderRadius="5px"
              height="30px"
            >
              {address.slice(0, 6) + "..." + address.slice(address.length - 4)}
            </Button>
          </HStack>
        </Center>
      </>
    );
  }

  return (
    <>
      {isDesktop ? (
        <Flex w="full" h="80px">
          <Box marginLeft="40px" marginTop="10px" flex={1}>
            <Link
              href="/"
              boxShadow="none !important"
              float="left"
            >
              <EtherstonesLogoSmall />
            </Link>
          </Box>
          <Box marginTop="35px" marginRight="35px">
            <Buttons />
          </Box>
        </Flex>
      ) : null}

      {!isDesktop ? (
        <VStack>
          <Center>
            <Link
              href="/"
              boxShadow="none !important"
              float="left"
            >
              <EtherstonesLogoSmall />
            </Link>
          </Center>
          <Center marginTop="10px">
            <Buttons />
          </Center>
        </VStack>
      ) : null}
    </>
  );
}
