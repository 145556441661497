import "./presale.css";
import { Box, Link, Button, Center } from "@chakra-ui/react";
import { EtherstonesLogoLarge } from "../assets/Icons";
import {
  connectWallet,
  getAddress,
  redirectIfNotConnected,
} from "../ConnectAccount";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getChainId } from "../Transactions";
const daiLogo = require("../assets/dai-logo.png");
const avaxLogo = require("../assets/avax-logo.png");
type PresaleHeaderProps = {
  showDai?: boolean;
}

export default function PresaleHeader(props: PresaleHeaderProps) {
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getAddress().then((res) => setAddress(res));
    //redirectIfNotWhitelistedOrConnected().then((res) => res ? navigate("../") : null);
    getChainId().then(chainId => {
      if (chainId != 43114 && chainId != 4) {
        alert("Please switch to the Avalanche Mainnet");
        navigate("../");
        return;
      }
    });
    // Subscribe to accounts change
    window.ethereum.on("accountsChanged", (accounts: string[]) => {
      redirectIfNotConnected().then((res) => {
        if (res) {
          navigate("../");
          window.location.reload();
        } else {
          connectWallet().then(() => {
            getAddress().then((res) => setAddress(res));
          });
        }
      });
    });
    // Subscribe to chainId change
    window.ethereum.on("chainChanged", (chainId: number) => {
        if (chainId != 43114 && chainId != 4) {
          navigate("../");
          window.location.reload();
        }
      });
  
      // Subscribe to provider disconnection
      window.ethereum.on(
        "disconnect",
        (error: { code: number; message: string }) => {
          navigate("/");
          window.location.reload();
        }
      );
  }, []);
  return (
    <Box>
      <Link href="/" marginTop="10px" boxShadow="none !important" marginLeft="30px" float="left">
        <EtherstonesLogoLarge />
      </Link>
      <Box float="right" marginTop="40px" marginRight="40px" className="presale-header-buttons">
        {props.showDai ? 
        <Link
          href="https://traderjoexyz.com/trade?outputCurrency=0xd586E7F844cEa2F87f50152665BCbc2C279D8d70#/"
          target="_blank"
        >
          <Button
            backgroundColor="white !important"
            color="black"
            padding="20px"
            borderRadius="5px"
            height="30px"
            marginRight="10px"
            id="presale-header-button-1"
          >
            <img src={daiLogo} width="25px" height="25px"></img>
            &nbsp;Get DAI.e
          </Button>
        </Link>
        :       <Link href="https://traderjoexyz.com/trade?outputCurrency=0x1efE6a8AF01920912F0B4f7F26fc16e294664c4C#/" target="_blank">
        <Button
          backgroundColor="white !important"
          color="black"
          padding="20px"
          marginRight="10px"
          borderRadius="5px"
          height="30px"
          maxW="147.39px"
        >
          <img src={avaxLogo} width="25px" height="25px"></img>
          &nbsp;Get $ETHS
        </Button>
      </Link> }
      <Button
          backgroundColor="white !important"
          color="black"
          padding="20px"
          borderRadius="5px"
          height="30px"
          marginRight="10px"
          maxW="147.39px"
          onClick={() => navigate("/dashboard")}
          id="presale-header-button-2"
        >
          Dashboard
        </Button>
        <Button
          backgroundColor="white !important"
          color="black"
          padding="20px"
          borderRadius="5px"
          height="30px"
          id="presale-header-button-3"
        >
          {address.slice(0, 6) + "..." + address.slice(address.length - 4)}
        </Button>
      </Box>
    </Box>
  );
}
