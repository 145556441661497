import CONSTANTS from "../../../Constants.json";
import React, { useEffect, useRef, useState } from "react";
import "./etherstonecardsmall.css";
import pebbles from "../../../assets/etherstones/pebbles";
import shards from "../../../assets/etherstones/shards";
import stones from "../../../assets/etherstones/stones";
import crystals from "../../../assets/etherstones/crystals";
import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import {
  claimEtherstoneReward,
  compoundEtherstoneReward,
} from "../../../Transactions";

export type EtherstoneProps = {
  name: String; // name of the etherstone
  id: number | string; // number ID
  tier: number; // 0: emerald, 1: sapphire, 2: amethyst, 3: ruby, 4: radiant
  type: String; // Pebble, Shard, Stone, or Crystal
  lockedAmount: Number; // locked ETHS
  pending: Number; // pending rewards to claim
  isPreview?;
  identifier?: string;
  timesCompounded?: number;
  lastInteract: Number;
};

export function EtherstoneCardSmall(props: EtherstoneProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const videos = document.querySelectorAll("video");
    videos.forEach((element) => {
      element.playbackRate = 0.5;
    });
    if(videoRef && videoRef.current) {
      videoRef.current.defaultMuted = true;
      videoRef.current.controls = false;
      videoRef.current.muted = true;
      videoRef.current.autoplay = true;
      videoRef.current.playsInline = true;
      videoRef.current.setAttribute("muted", "");

      setTimeout(() => {
        const promise = videoRef.current?.play();
      }, 0)
    }
  });
  const hoverClasses = [
    "hover-color-emeraldS",
    "hover-color-sapphireS",
    "hover-color-amethystS",
    "hover-color-rubyS",
    "hover-color-radiantS",
  ];
  const hoverClass = hoverClasses[props.tier];

  const tierMapping = ["Emerald", "Sapphire", "Amethyst", "Ruby", "Radiant"];

  var videoToPlay;
  var baseDailyReward;
  var compoundBonus;

  if (props.type === "Pebble") {
    videoToPlay = pebbles[props.tier];
    baseDailyReward = CONSTANTS.Pebble.DailyReturn;
    compoundBonus = props.timesCompounded
      ? props.timesCompounded * CONSTANTS.Pebble.CompoundBonus
      : 0;
  } else if (props.type === "Shard") {
    videoToPlay = shards[props.tier];
    baseDailyReward = CONSTANTS.Shard.DailyReturn;
    compoundBonus = props.timesCompounded
      ? props.timesCompounded * CONSTANTS.Shard.CompoundBonus
      : 0;
  } else if (props.type === "Stone") {
    videoToPlay = stones[props.tier];
    baseDailyReward = CONSTANTS.Stone.DailyReturn;
    compoundBonus = props.timesCompounded
      ? props.timesCompounded * CONSTANTS.Stone.CompoundBonus
      : 0;
  } else if (props.type === "Crystal") {
    videoToPlay = crystals[props.tier];
    baseDailyReward = CONSTANTS.Crystal.DailyReturn;
    compoundBonus = props.timesCompounded
      ? props.timesCompounded * CONSTANTS.Crystal.CompoundBonus
      : 0;
  }
  compoundBonus = parseFloat(compoundBonus.toFixed(5));

  var timeElapsed = parseInt(
    (Date.now() / 1000 - parseInt(props.lastInteract.toString())).toString()
  );

  const [showCooldown, setShowCooldown] = useState(false);
  const [cooldown, setCooldown] = useState(
    CONSTANTS.NodeCooldown - timeElapsed
  );

  useEffect(() => {
    if (timeElapsed > CONSTANTS.NodeCooldown) {
      setShowCooldown(false);
      return;
    }
    setShowCooldown(true);
    const timerInterval = setInterval(() => {
      setCooldown(cooldown - 1);
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [cooldown]);

  var isDisabled = props.isPreview ? true : false;
  var etherstoneId = props.identifier ? props.identifier : undefined;

  const cooldownClass = showCooldown ? "cooldown-button" : "";

  return (
    <Box className="etherstone-ctrS" id={etherstoneId}>
      <div className={hoverClass}>
        <div className="overlayS">
          <div className="overlay-bgS">
            <div className="overlay-elementS top-leftS"></div>
            <div className="overlay-elementS top-rightS"></div>
            <div className="overlay-elementS bottom-leftS"></div>
            <div className="overlay-elementS bottom-rightS"></div>
            <div className="etherstone-card-titleS">{props.name}</div>
            <div className="locked-ethsS">
              Locked Amount
              <div className="stats-description-leftS">
                {props.lockedAmount} ETHS
              </div>
            </div>
            <div className="daily-compound-rewardS">
              Daily Reward
              <div className="stats-description-rightS">
                {baseDailyReward}% + {compoundBonus}%
              </div>
            </div>
            <div className="etherstone-card-footerS">
              {tierMapping[props.tier] + " " + props.type}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="dotS"></span>
              &nbsp;&nbsp;Etherstone #{props.id}
            </div>
          </div>
          <div className="pending-rewardS">Pending: {props.pending} ETHS</div>
          <div className="etherstone-button-ctrS">
            <button
              className={cooldownClass + " claim-etherstone-btnS"}
              disabled={isDisabled}
            >
              {showCooldown ? "Cooldown:" : "Claim Pending"}
            </button>
            <button
              className={
                cooldownClass +
                "-rightS compound-etherstone-btnS etherstone-rightS"
              }
              disabled={isDisabled}
            >
              Compound Pending
            </button>
          </div>
          <div className="etherstone-vid-ctrS">
          <div dangerouslySetInnerHTML={{__html: `<video className="small-video" ref=${videoRef} muted loop autoPlay playsInline src=${videoToPlay}></video>`}}></div> 
          </div>
        </div>
      </div>
    </Box>
  );
}
