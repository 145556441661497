import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";

export async function connectWallet() {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          43114: "https://api.avax.network/ext/bc/C/rpc",
        },
      },
    },
  };

  let web3Modal;
  web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions, // required
    disableInjectedProvider: false,
  });
  const instance = await web3Modal?.connect();
  const provider = new ethers.providers.Web3Provider(instance);
  return provider;
}

export async function getAddress() {
  const ethereum = window.ethereum;
  if (!ethereum.selectedAddress) {
    await ethereum.request({ method: 'eth_requestAccounts' });
  }
  return ethereum.selectedAddress;
}

export async function redirectIfNotConnected() {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  return (await provider.listAccounts()).length == 0 ? true : false;
}