import "./lottery.css";
import {
  Box,
  Button,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Text,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import { Wheel } from "react-custom-roulette";
import { useEffect, useState } from "react";
import LandingNavbar from "../Dashboard/DashboardNavbar";
import PresaleHeader from "../Presale/PresaleHeader";
import {
  getLotteryCooldown,
  getTotalLockedAndEntryPrice,
  playLottery,
  watchLottery,
} from "./LotteryTransactions";
import CONSTANTS from "../Constants.json";
import { convertTime } from "../Dashboard/DashboardComponents/EtherVault";
import { convertToHHMMSS } from "../Dashboard/EtherstoneCard/EtherstoneCard";
import { getAddress } from "../ConnectAccount";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function formatLottery(lower, upper) {
  var lowerString;
  var upperString;
  if (lower.toFixed(0) == 0) {
    lowerString = parseFloat(lower.toFixed(2));
  } else if (lower.toFixed(0).length == 1) {
    lowerString = parseFloat(lower.toFixed(1));
  } else {
    lowerString = parseFloat(lower.toFixed(0));
  }
  if (upper.toFixed(0) == 0) {
    upperString = parseFloat(upper.toFixed(2));
  } else if (lower.toFixed(0).length == 1) {
    upperString = parseFloat(upper.toFixed(1));
  } else {
    upperString = parseFloat(upper.toFixed(0));
  }
  return lowerString + "-" + upperString + " ETHS";
}

const bounds = [0, 10, 10 / 3, 2, 10 / 7, 10 / 9];

function getData(maxWin) {
  return [
    {
      option: formatLottery(bounds[0], maxWin / bounds[1]),
      style: { backgroundColor: "lightblue", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[1], maxWin / bounds[2]),
      style: { backgroundColor: "#f5eb8e", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[2], maxWin / bounds[3]),
      style: { backgroundColor: "#c892f7", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[3], maxWin / bounds[4]),
      style: { backgroundColor: "green", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[4], maxWin / bounds[5]),
      style: { backgroundColor: "purple", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[5], maxWin),
      style: { backgroundColor: "orange", textColor: "black" },
    },
    {
      option: formatLottery(bounds[0], maxWin / bounds[1]),
      style: { backgroundColor: "lightblue", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[1], maxWin / bounds[2]),
      style: { backgroundColor: "#f5eb8e", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[2], maxWin / bounds[3]),
      style: { backgroundColor: "#c892f7", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[3], maxWin / bounds[4]),
      style: { backgroundColor: "green", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[4], maxWin / bounds[5]),
      style: { backgroundColor: "purple", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[5], maxWin),
      style: { backgroundColor: "orange", textColor: "black" },
    },
    {
      option: formatLottery(bounds[0], maxWin / bounds[1]),
      style: { backgroundColor: "lightblue", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[1], maxWin / bounds[2]),
      style: { backgroundColor: "#f5eb8e", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[2], maxWin / bounds[3]),
      style: { backgroundColor: "#c892f7", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[3], maxWin / bounds[4]),
      style: { backgroundColor: "green", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[4], maxWin / bounds[5]),
      style: { backgroundColor: "purple", textColor: "black" },
    },
    {
      option: formatLottery(maxWin / bounds[5], maxWin),
      style: { backgroundColor: "orange", textColor: "black" },
    },
    { option: "JACKPOT", style: { backgroundColor: "red" } },
  ];
}

function getWinningNumber(maxWin, wonAmount) {
  const blue = [0, 6, 12];
  const yellow = [1, 7, 13];
  const lightpurple = [2, 8, 14];
  const green = [3, 9, 15];
  const purple = [4, 10, 16];
  const orange = [5, 11, 17];
  const jackpot = [18, 18, 18];

  function inRange(min, max) {
    return (
      wonAmount >= (min == 0 ? 0 : maxWin / min) && wonAmount <= maxWin / max
    );
  }

  var winArray;
  if (inRange(bounds[0], bounds[1]) || wonAmount <= maxWin / bounds[1]) {
    winArray = blue;
  } else if (inRange(bounds[1], bounds[2]) || wonAmount <= maxWin / bounds[2]) {
    winArray = yellow;
  } else if (inRange(bounds[2], bounds[3]) || wonAmount <= maxWin / bounds[3]) {
    winArray = lightpurple;
  } else if (inRange(bounds[3], bounds[4]) || wonAmount <= maxWin / bounds[4]) {
    winArray = green;
  } else if (inRange(bounds[4], bounds[5] || wonAmount <= maxWin / bounds[5])) {
    winArray = purple;
  } else if (inRange(bounds[5], 1) || wonAmount <= maxWin) {
    winArray = orange;
  } else {
    winArray = jackpot;
  }

  return winArray[getRandomInt(0, 3)];
}

export default function Lottery() {
  const [spinning, setSpinning] = useState(false);
  const [totalLocked, setTotalLocked] = useState("0");
  const [entryPrice, setEntryPrice] = useState("0");
  const [maxWin, setMaxWin] = useState(0);

  const [pending, setPending] = useState(false);
  const [winnings, setWinnings] = useState(0);
  const [winningNumber, setWinningNumber] = useState(-1);

  const [buttonText, setButtonText] = useState("Spin");
  const [cooldown, setCooldown] = useState(0);

  const [address, setAddress] = useState("");

  useEffect(() => {
    getTotalLockedAndEntryPrice().then((res) => {
      setTotalLocked(res[0]);
      setEntryPrice(parseFloat(res[1]).toFixed(6));
      setMaxWin(Number(res[0]) * (CONSTANTS.MaximumLotteryWinnings / 100));
    });
    getLotteryCooldown().then((res) => {
      setCooldown(res);
    });
    var now = Math.floor(Date.now() / 1000);
    if (cooldown < now) {
      setButtonText("Spin (" + entryPrice + " AVAX)");
    } else {
      setButtonText(convertTime(cooldown - now));
    }
    getAddress().then((res) => {
      setAddress(res);
    });
  }, []);

  useEffect(() => {
    const spinner = document.getElementById("spinner");
    if (spinner && pending) {
      spinner.style.display = "block";
    } else if (spinner && !pending) {
      spinner.style.display = "none";
    }
    var now = Math.floor(Date.now() / 1000);
    if (cooldown < now) {
      setButtonText("Spin (" + entryPrice + " AVAX)");
    } else {
      setButtonText(convertToHHMMSS(cooldown - now));
    }
  });

  useEffect(() => {
    if (buttonText.length <= 8) {
      const interval = setInterval(() => {
        var now = Math.floor(Date.now() / 1000);
        setButtonText(convertToHHMMSS(cooldown - now));
        if (cooldown - now < 0) {
          setButtonText("Spin (" + entryPrice + " AVAX)");
          clearInterval(interval);
        }
      }, 1000);
    }
  }, [buttonText]);

  useEffect(() => {
    const timer = () =>
      setTimeout(() => {
        window.location.reload();
      }, 864000);
    const timerId = timer();
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  useEffect(() => {
    if (winningNumber != -1) {
      setSpinning(true);
    }
  }, [winningNumber]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isInfoOpen,
    onOpen: onInfoOpen,
    onClose: onInfoClose,
  } = useDisclosure();
  return (
    <Box id="lottery-bg">
      <PresaleHeader />
      <Box marginLeft="10px" marginRight="10px">
        <Box marginTop="-30px">
          <Center fontSize="35px" marginTop="150px" color="#b4b3ff">
            Etherstones: Player-Sided Lottery
          </Center>
          <Center fontSize="25px" marginTop="5px">
            The first player-sided lottery game. Players have a 7% edge on the
            house.
          </Center>
          <Center fontSize="20px" marginTop="5px">
            Your total locked: {parseFloat(totalLocked.toString()).toFixed(2)}{" "}
            ETHS
          </Center>
          <Center marginBottom="20px">
            <Button
              zIndex={10000}
              marginTop="15px"
              onClick={onInfoOpen}
              bgColor="#b4b3ff !important"
              _hover={{ bg: "#81b2fc !important" }}
              color="blue"
            >
              How It Works
            </Button>
          </Center>
          <Center top={0} right={0} left={0} bottom={0}>
            <Wheel
              mustStartSpinning={spinning}
              prizeNumber={winningNumber}
              data={getData(maxWin)}
              onStopSpinning={() => {
                setSpinning(false);
                setWinningNumber(-1);
                onOpen();
                getLotteryCooldown().then((res) => {
                  setCooldown(res);
                });
                var now = Math.floor(Date.now() / 1000);
                if (cooldown < now) {
                  setButtonText("Spin (" + entryPrice + " AVAX)");
                } else {
                  setButtonText(convertToHHMMSS(cooldown - now));
                }
              }}
              backgroundColors={["#3e3e3e", "#df3428"]}
              textColors={["#ffffff"]}
              radiusLineWidth={2}
              outerBorderWidth={4}
            />
          </Center>
          <Center marginTop="20px">
            <Button
              color="black"
              bgColor="#af79c7 !important"
              _hover={{ bg: "#d197ce !important" }}
              minW="70px"
              onClick={() => {
                playLottery().then(() => {
                  setPending(true);
                  watchLottery().then((res) => {
                    setPending(false);
                    setWinnings(Number(res));
                    setWinningNumber(getWinningNumber(maxWin, res));
                  });
                });
              }}
            >
              {pending ? "" : spinning ? "Playing..." : buttonText}
            </Button>
            <svg id="spinner" className="lottery-spinner" viewBox="0 0 50 50">
              <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
              ></circle>
            </svg>
          </Center>
          <Center marginTop="20px">
            Currently the wheel does not spin on mobile (entering the lottery still works).
          </Center>
          <Center>
          <Box display="inline">
            If the transaction completes and the wheel doesn't spin, you can
            view your winnings&nbsp;
            <Link
              color="#b4b3ff"
              href={`https://snowtrace.io/address/${address}#tokentxns`}
              target="_blank"
            >
              here (snowtrace).
            </Link>
          </Box>
          </Center>
          <Center>
            The amount you won will be the value of the most recent transaction.
          </Center>
        </Box>
        <Modal isCentered isOpen={isInfoOpen} onClose={onInfoClose}>
          <ModalOverlay />
          <ModalContent maxW="600px" bgColor="rgba(22, 12, 34)" color="white">
            <ModalHeader>
              <Center fontSize="20px">
                <b>ETHS Lottery Details</b>
              </Center>
            </ModalHeader>
            <ModalBody>
              <Center fontSize="18px" fontWeight="600">
                How it works
              </Center>
              <Center marginTop="7px" fontSize="16px">
                Each time you play the lottery, you are able to win between 0 to
                5% of the total locked ETHS in all of your Etherstones. The
                lottery is playable every 24 hours.
              </Center>
              <Center marginTop="7px" fontSize="16px">
                The player has a edge of 7%. The lottery entry price will
                fluctuate with the price of ETHS to maintain the 7% edge.
              </Center>
              <Center marginTop="7px" fontSize="18px" fontWeight="600">
                What is the entry fee?
              </Center>
              <Center marginTop="7px" fontSize="16px">
                The entry fee is the AVAX you pay to play the lottery. It is
                calculated in real-time at a 7% edge for the player.
              </Center>
              <Center marginTop="7px" fontSize="18px" fontWeight="600">
                What is the Jackpot prize?
              </Center>
              <Center marginTop="7px" fontSize="16px">
                Winning the Jackpot has a 1 in 500 chance and will give you 50%
                of your total locked ETHS.
              </Center>
              <Center marginTop="7px" fontSize="18px" fontWeight="600">
                What are the odds for the other prizes?
              </Center>
              <Center marginTop="7px" fontSize="16px">
                The odds to win any of the other prizes is equal. The jackpot
                prize is the only exception.
              </Center>
              <Center marginTop="7px" fontSize="18px" fontWeight="600">
                Can I increase my locked ETHS?
              </Center>
              <Center marginTop="7px" fontSize="16px">
                Yes. If you create more Etherstones, your locked ETHS will
                increase and the rewards from the lottery will also increase.
              </Center>
              <Center marginTop="7px" fontSize="18px" fontWeight="600">
                Where does the AVAX go?
              </Center>
              <Center marginTop="7px" fontSize="16px">
                80% of the AVAX is instantly used for a buyback and added to
                liquidity. The other 20% is used to fund marketing &
                developemnt.
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={onInfoClose}
                bgColor="#af79c7 !important"
                _hover={{ bg: "#d197ce !important" }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isCentered size={"xs"} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent bgColor="rgba(22, 12, 34)" color="white">
            <ModalHeader>
              <Center>
                <b>Congrats!</b>
              </Center>
            </ModalHeader>
            <ModalBody>
              <Center fontSize="19px">
                &#127881;You won {winnings.toFixed(2)} ETHS!! &#127881;
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={onClose}
                bgColor="#af79c7 !important"
                _hover={{ bg: "#d197ce !important" }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}
