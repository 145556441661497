import emerald from "./stone_emerald.webm";
import sapphire from "./stone_sapphire.webm";
import amethyst from "./stone_amethyst.webm";
import ruby from "./stone_ruby.webm";
import radiant from "./stone_radiant.webm";

const stones = [
    emerald,
    sapphire,
    amethyst,
    ruby,
    radiant
]

export default stones;