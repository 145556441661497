import React from "react";
import {
  LinkBox,
  LinkOverlay,
  Button,
  Link,
  Center,
  Flex,
  HStack,
  VStack,
  useMediaQuery,
  Box,
} from "@chakra-ui/react";
import {
  DexscreenerIcon,
  DiscordIconWhite,
  EtherstonesLogoLarge,
  TwitterIcon,
  WhitepaperIcon,
} from "../assets/Icons";
import { useNavigate } from "react-router-dom";
import { connectWallet } from "../ConnectAccount";

const avaxLogo = require("../assets/avax-logo.png");

export default function LandingNavbar() {
  const navigate = useNavigate();
  const [isDesktop] = useMediaQuery("(min-width: 900px)");

  function SVGGenerator(props: any) {
    return (
      <LinkBox width="50px" padding="10px">
        <LinkOverlay href={props.href} title={props.title} target="_blank">
          {props.svg}
        </LinkOverlay>
      </LinkBox>
    );
  }

  function openDashboard() {
    connectWallet().then(() => {
      //@TODO: remove ropsten network id
      if (window.ethereum.chainId != 0xa86a && window.ethereum.chainId != 4) {
        alert("Please switch to the Avalanche Mainnet");
        return;
      }
      document.getElementById("pauseBtn")?.click();
      navigate("/dashboard");
    });
  }

  function SVGList() {
    return (
      <>
        <SVGGenerator
          href={"https://discord.gg/etherstones"}
          svg={<DiscordIconWhite />}
          title={"Discord"}
        />
        <SVGGenerator
          href={"https://twitter.com/EtherstonesFi"}
          svg={<TwitterIcon />}
          title={"Twitter"}
        />
        <SVGGenerator
          href={"https://docs.etherstones.fi/"}
          svg={<WhitepaperIcon />}
          title={"Whitepaper"}
        />
        <SVGGenerator
          href={"https://dexscreener.com/avalanche/0x78871b497613dc9b9886167e75e6ffd57d351046"}
          svg={<DexscreenerIcon />}
          title={"Dexscreener"}
        />
      </>
    );
  }

  function GetETHSButton() {
    return (
      <Link href="https://traderjoexyz.com/trade?outputCurrency=0x1efE6a8AF01920912F0B4f7F26fc16e294664c4C#/" target="_blank">
        <Button
          backgroundColor="white !important"
          color="black"
          padding="20px"
          borderRadius="5px"
          height="30px"
        >
          <img src={avaxLogo} width="25px" height="25px"></img>
          &nbsp;Get $ETHS
        </Button>
      </Link>
    );
  }

  function DashboardButton() {
    return (
      <Button
        backgroundColor="white !important"
        color="black"
        padding="20px"
        borderRadius="5px"
        height="30px"
        onClick={() => {
          openDashboard();
        }}
        id="open-dashboard-button"
      >
        Open Dashboard
      </Button>
    );
  }

  return (
    <Box>
      <Flex
        w="full"
        h="80px"
        className="nav"
        position={isDesktop ? "fixed" : "static"}
        zIndex="10"
        flexWrap="nowrap"
      >
        {isDesktop ? (
          <HStack w="250px" marginLeft="20px">
            <SVGList />
          </HStack>
        ) : (
          <VStack w="100px">
            <SVGList />
          </VStack>
        )}
        <Center flex={1}>
          {isDesktop ? (
            <Box id="etherstones-logo-large" marginLeft="55px" marginTop="25px">
              <EtherstonesLogoLarge />
            </Box>
          ) : null}
        </Center>
        <Center maxW="360px">
          <HStack marginRight="30px">{isDesktop ? <><GetETHSButton /> <DashboardButton/></>: null}</HStack>
        </Center>
      </Flex>
      {!isDesktop ? (
        <Box marginTop="-60px">
          <Center marginLeft="40px">
            <EtherstonesLogoLarge />
          </Center>
          <Center marginLeft="40px" className="nav">
            <VStack>
            <GetETHSButton />
            <DashboardButton />
            </VStack>
          </Center>
        </Box>
      ) : null}
    </Box>
  );
}
