import "./footer.css";
import { Box, Center } from "@chakra-ui/react";
import { EtherstonesLogoSmall } from "../assets/Icons";

export default function Footer() {
  return (
    <div className="footer-dark">
      <footer>
        <div className="footer-container">
          <div className="footer-grid">
            <div className="footer-description">
              <EtherstonesLogoSmall />
              <p style={{fontSize: "11px", fontWeight: "100"}}>
                The information contained on this Website is not intended <br/> to be taken
                as financial advice. Etherstones is not responsible <br/> for any damages that may result,
                and we do not guarantee <br/> that information on this Website is up to date at all times.
                <br/> <br/>
              </p>
              <p className="copyright">Etherstones © 2022. All rights reserved.</p>
            </div>
            <div className="footer-links-columns first-link">
              <div className="footer-header">Official Links</div>
              <ul>
                <li>
                  <a href="https://docs.etherstones.fi/" target="_blank">Whitepaper</a>
                </li>
                <li>
                  <a href="https://www.assuredefi.io/projects/etherstones/" target="_blank">Team KYC</a>
                </li>
                <li>
                  <a href="https://dexscreener.com/avalanche/0x78871b497613dc9b9886167e75e6ffd57d351046" target="_blank">ETHS Chart</a>
                </li>
                <li>
                  <a href="https://snowtrace.io/address/0x1efe6a8af01920912f0b4f7f26fc16e294664c4c#code" target="_blank">Smart Contracts</a>
                </li>
                <li>
                  <a href="https://www.team.finance/view-coin/0x1efE6a8AF01920912F0B4f7F26fc16e294664c4C?name=Etherstones&symbol=ETHS" target="_blank">Liquidity Lock</a>
                </li>
                <li>
                  <a href="https://traderjoexyz.com/trade?outputCurrency=0x1efE6a8AF01920912F0B4f7F26fc16e294664c4C#" target="_blank">Get $ETHS</a>
                </li>
              </ul>
            </div>
            <div className="footer-links-columns">
              <div className="footer-header">Community</div>
              <ul>
                <li>
                  <a href="https://twitter.com/home">Twitter</a>
                </li>
                <li>
                  <a href="https://discord.gg/etherstones">Discord</a>
                </li>
              </ul>
            </div>
            <div className="footer-links-columns">
              <div className="footer-header">Contact</div>
              <ul>
                <li>
                  <a>contact@etherstones.fi</a>
                </li>
                <li><div>&nbsp;</div></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
