import CONSTANTS from "../Constants.json";
import { Text } from "@chakra-ui/react";

type Etherstone = {
  Name: String;
  DailyReturn: Number;
  CompoundBonus: Number;
  MinCost: Number;
  MaxCost: Number;
};

export const PebbleDescription = descriptionBuilder(CONSTANTS.Pebble);
export const ShardDescription = descriptionBuilder(CONSTANTS.Shard);
export const StoneDescription = descriptionBuilder(CONSTANTS.Stone);
export const CrystalDescription = descriptionBuilder(CONSTANTS.Crystal);

export const PebblePlaceholder = `Enter a number between ${CONSTANTS.Pebble.MinCost} and ${CONSTANTS.Pebble.MaxCost}`;
export const ShardPlaceholder = `Enter a number between ${CONSTANTS.Shard.MinCost} and ${CONSTANTS.Shard.MaxCost}`;
export const StonePlaceholder = `Enter a number between ${CONSTANTS.Stone.MinCost} and ${CONSTANTS.Stone.MaxCost}`;
export const CrystalPlaceholder = `Enter a number between ${CONSTANTS.Crystal.MinCost} and ${CONSTANTS.Crystal.MaxCost}`;

function descriptionBuilder(etherstone: Etherstone) {
  return `Permanently lock between ${etherstone.MinCost} and ${etherstone.MaxCost} ETHS in a ${etherstone.Name} Etherstone. 
    Your Etherstone will generate you ${etherstone.DailyReturn}% returns per day, with a compound bonus 
    of ${etherstone.CompoundBonus}% available every 48 hours.`;
}

export function Description(props) {
  return <Text fontSize="13px">{props.content}</Text>;
}

export function windowAlert(msg?) {
  msg
    ? alert(msg)
    : alert("Error occurred, please make sure your inputs are correct.");
}
