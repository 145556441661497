import { createRef, RefObject, useEffect, useRef, useState } from "react";
import { Button, Text, Box, Center, Link } from "@chakra-ui/react";
import LandingNavbar from "../Landing/LandingNavbar";
import "../Landing/landing.css";
import { DiscordIconWhite, DownArrowIcon, TwitterIcon } from "../assets/Icons";
import { EtherstoneCard } from "../Dashboard/EtherstoneCard/EtherstoneCard";
import { EtherstoneCardSmall } from "../Dashboard/EtherstoneCard/EtherstoneCardSmall/EtherstoneCardSmall";
import Fade from "react-reveal/Fade";
import Footer from "../Footer/Footer";

export default function LandingNoRender() {
  useEffect(() => {
    window.onscroll = () => {
      var logo = document.getElementById("etherstones-logo-large");
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      const scrolled = winScroll / height;
      //@ts-ignore
      if (logo) {
        (logo as any).style.opacity = 1 - scrolled * 6;
      }
    };
  });

  const secondPageRef: RefObject<HTMLDivElement> = createRef();

  function scrollToSecondPage() {
    secondPageRef!.current!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  return (
    <Box>
      <LandingNavbar />
      <Button id="pauseBtn" display="none"></Button>
      <Button id="randomizeBtn" display="none"></Button>
      <Box id="closed-render-message" display="block !important">
        The render is not loaded on this page.
      </Box>
      <Box marginTop="100vh" width="10px" bgColor="white" position="absolute">
        <Box width="100vw">
          <Center className="apr-landing">
            <Box className="apr-text">
              Earn 450%+ APR
              <Center>
                <a className="scroll-svg" onClick={() => scrollToSecondPage()}>
                  <DownArrowIcon />
                </a>
              </Center>
            </Box>
          </Center>
        </Box>
      </Box>
      <Box>
        <Box
          className="second-page"
          color="white"
          ref={secondPageRef as RefObject<HTMLDivElement>}
        >
          <Fade bottom cascade duration={800}>
            <Center
              fontSize="40px"
              fontWeight="300"
              marginTop="140px"
              color="#b4b3ff"
              className="what-is-etherstones"
            >
              What is Etherstones?
            </Center>
            <div className="second-page-content">
              <div className="second-page-content-inner">
                <div className="second-page-content-left">
                  <Text
                    marginBottom="7px"
                    fontSize="20px"
                    className="second-page-content-left-header"
                  >
                    Etherstones is an innovative DaaS on the Avalanche Network,
                    focused on long-term growth and sustainability.
                  </Text>
                  <Text
                    fontWeight="200"
                    marginBottom="10px"
                    marginLeft="10px"
                    marginRight="25px"
                    color="#c3c3db"
                  >
                    We are the fusion of Nodes as a Service and Reflection
                    tokenomics. Leverage our unique protocol to generate passive
                    income in multiple ways. We provide income to everyone in
                    the form of Ethereum (wETH.e on Avalanche) and our native
                    token, ETHS.
                  </Text>
                  <Text
                    marginBottom="7px"
                    fontSize="20px"
                    className="second-page-content-left-header"
                  >
                    Get started with as little as 5 ETHS to earn lifetime
                    rewards from your Etherstone. We currently offer daily
                    returns up to 1.578%.
                  </Text>
                  <Text
                    fontWeight="200"
                    marginBottom="20px"
                    marginLeft="10px"
                    marginRight="25px"
                    color="#c3c3db"
                  >
                    Etherstones are more than just nodes. They are soon-to-be
                    NFTs waiting to be released into the DeFi ecosystem and
                    taken cross-chain.
                  </Text>
                  <Center fontSize="25px" color="#dcdce8" marginBottom="3px">
                    Have Questions? Read our Docs:
                  </Center>
                  <Center marginBottom="6px">
                    <Link href="https://docs.etherstones.fi/" target="_blank">
                      <Button className="second-page-buttons">Docs</Button>
                    </Link>
                  </Center>
                  <Center fontSize="25px" color="#dcdce8">
                    <Text marginTop="5px">Or Summon an Etherstone:</Text>
                  </Center>
                  <Center>
                    <Button
                      className="second-page-buttons"
                      onClick={() => {
                        document
                          .getElementById("open-dashboard-button")
                          ?.click();
                      }}
                    >
                      Dashboard
                    </Button>
                  </Center>
                </div>
                <div className="second-page-content-right">
                  <Center
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    h="100%"
                  >
                    <Box id="etherstone-card-genesis">
                      <EtherstoneCard
                        name={"Genesis"}
                        id={"0"}
                        tier={4}
                        type={"Crystal"}
                        lockedAmount={1000}
                        pending={0.0}
                        lastInteract={0}
                        timesCompounded={108}
                        isPreview
                      />
                    </Box>
                  </Center>
                </div>
              </div>
              <div className="second-page-bottom-grid">
                <div className="second-page-bottom-1">
                  <Center fontWeight="400" fontSize="25px" color="#b4b3ff">
                    <Text>Sustainable Yield</Text>
                  </Center>
                  <Text color="#c3c3db" fontSize="18px">
                    We offer realistic daily returns through re-investing our
                    Treasury into the project. Our treasury investments range
                    from safe protocols such as Delta Neutral to riskier
                    investments in emerging protocols. This allows us to perform
                    routine buybacks to mantain our high APR.
                  </Text>
                </div>
                <div className="second-page-bottom-2">
                  <Center fontWeight="400" fontSize="25px" color="#b4b3ff">
                    <Text>Community-Driven</Text>
                  </Center>
                  <Text color="#c3c3db" fontSize="18px">
                    One of our biggest goals is to become as decentralized as
                    possible. Community votes will be held if any sort of issue
                    arises, and we will take any steps necessary to maintain
                    Etherstones long term. Our community is our top priority and
                    we aim to be as transparent as possible.
                  </Text>
                </div>
                <div className="second-page-bottom-3">
                  <Center fontWeight="400" fontSize="25px" color="#b4b3ff">
                    <Text>Long Term</Text>
                  </Center>
                  <Text color="#c3c3db" fontSize="18px">
                    Etherstones is not going anywhere. We are designed to last
                    long term and will continuously focus on improving our
                    project. Expect new and exciting innovations in the realm of
                    P2E games, cross-chain protocol expansions, and more NFTs.
                    This is only the beginning.
                  </Text>
                </div>
              </div>
            </div>
            <Box className="third-page" color="white">
              <Center
                fontSize="40px"
                fontWeight="300"
                marginTop="175px"
                color="#b4b3ff"
              >
                How it Works
              </Center>
              <Center
                fontSize="18px"
                color="#dcdce8"
                marginTop="20px"
              >
                <Text
                  width="800px"
                  textAlign="center"
                  className="how-it-works-desc"
                >
                  Create an Etherstone or stake your ETHS into the Vault to earn
                  sustainable and fully transparent passive income. Generated
                  fees are distributed to Vault stakers and reinvested into the
                  project to maintain high APR and a healthy ecosystem.
                </Text>
              </Center>
              <div className="third-page-inner">
                <Fade bottom cascade duration={800}>
                  <div className="third-page-top-left">
                    <Center
                      fontSize="25px"
                      fontWeight="400"
                      color="#b4b3ff"
                      className="create-etherstone-header"
                    >
                      Create an Etherstone
                    </Center>
                    <Box marginLeft="100px" marginRight="50px">
                      <Box
                        color="#c3c3db"
                        marginTop="10px"
                        marginLeft="20px"
                        fontSize="18px"
                        className="create-etherstone-info"
                      >
                        <ol>
                          <li>
                          <a href="https://traderjoexyz.com/trade?outputCurrency=0x1efE6a8AF01920912F0B4f7F26fc16e294664c4C#/" target="_blank">Buy $ETHS - Available on TraderJoe</a>
                          </li>
                          <li>Create one of four types of Nodes:</li>
                          <Box marginLeft="20px">
                            <ul>
                              <li>Pebble: 5 - 8 $ETHS</li>
                              <li>Shard: 25 - 40 $ETHS</li>
                              <li>Stone: 125 - 200 $ETHS</li>
                              <li>Crystal: 625 - 1000 $ETHS</li>
                            </ul>
                          </Box>
                          <li>Earn daily rewards&#8212;up to 1.578%</li>
                          <li>
                            Compound your rewards into the Vault to increase
                            your daily earnings and receive passive Ethereum
                            reflections
                          </li>
                        </ol>
                      </Box>
                    </Box>
                  </div>
                  <div className="third-page-top-right">
                    <Box className="etherstones-small-container">
                      <EtherstoneCardSmall
                        name={"My Sapphire"}
                        id={"0"}
                        tier={1}
                        type={"Shard"}
                        lockedAmount={40}
                        pending={0.0}
                        lastInteract={0}
                        timesCompounded={8}
                        isPreview
                      />
                      <EtherstoneCardSmall
                        name={"My Ruby"}
                        id={"0"}
                        tier={3}
                        type={"Stone"}
                        lockedAmount={200}
                        pending={0.0}
                        lastInteract={0}
                        timesCompounded={88}
                        isPreview
                      />
                    </Box>
                  </div>
                  <div className="third-page-bottom-left">
                    <div className="landing-eth-container">
                      <div className="wrapper">
                        <div className="pyramid">
                          <div className="landing-square">
                            <div className="landing-triangle"></div>
                            <div className="landing-triangle"></div>
                            <div className="landing-triangle"></div>
                            <div className="landing-triangle"></div>
                          </div>
                        </div>

                        <div className="pyramid inverse">
                          <div className="landing-square">
                            <div className="landing-triangle"></div>
                            <div className="landing-triangle"></div>
                            <div className="landing-triangle"></div>
                            <div className="landing-triangle"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="third-page-bottom-right">
                    <Center
                      fontSize="25px"
                      fontWeight="400"
                      color="#b4b3ff"
                      className="stake-vault-header"
                    >
                      Stake in the Vault
                    </Center>
                    <Box
                      marginLeft="40px"
                      marginRight="40px"
                      className="vault-details"
                    >
                      <Box
                        color="#c3c3db"
                        marginTop="10px"
                        marginRight="20px"
                        fontSize="18px"
                      >
                        <Text>
                          80% of all taxes are distributed to Vault stakers.
                          Earn passive Ethereum income just by holding $ETHS in
                          the Vault.
                        </Text>
                        <Text marginTop="7px">
                          Our Etherstones permanently generate $ETHS allowing
                          for a steady flow of taxes into the Vault. More
                          Etherstones in existance means more taxes to be
                          distributed. Unlike other reflectionary tokens, our
                          Vault will never suffer from a lack of volume.
                        </Text>
                      </Box>
                    </Box>
                  </div>
                </Fade>
              </div>
            </Box>
            <Box className="final-page" color="white">
              <Center>
                <Box className="final-page-content">
                  <Center
                    fontSize="40px"
                    fontWeight="300"
                    marginTop="175px"
                    color="#b4b3ff"
                  >
                    Into the Metaverse
                  </Center>
                  <Center>
                    <Text
                      fontSize="20px"
                      marginTop="30px"
                      color="#dcdce8"
                      textAlign="center"
                    >
                      We have big plans for the future. Etherstones is in its
                      early stages and only just getting started. Get ready for
                      Play to Earn games, NFTs, cross-chain expansions, and a
                      full-on brand in the Metaverse.
                    </Text>
                  </Center>
                  <Center>
                    <Text fontSize="20px" marginTop="30px" color="#dcdce8">
                      Keep up to date with us: <br />
                    </Text>
                  </Center>
                  <a href="https://twitter.com/etherstonesfi" target="_blank">
                    <Center fontSize="26px" marginTop="10px" color="#dcdce8">
                      Twitter &nbsp; <TwitterIcon />
                      &nbsp;
                    </Center>
                  </a>
                  <a href="https://discord.gg/etherstones" target="_blank">
                    <Center fontSize="26px" marginTop="10px" color="#dcdce8">
                      Discord &nbsp; <DiscordIconWhite />
                    </Center>
                  </a>
                </Box>
              </Center>
            </Box>
          </Fade>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
