import { Box, Button, Center, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import PresaleHeader from "./PresaleHeader";
import "./options.css";
import { DownArrowIcon1 } from "../assets/Icons";
import { addpETHSToWallet, approveDAIForCrystalContract, getDAIBalance, 
  getDAIAllowanceForCrystalContract, addpMAXCRYSTALToWallet, swapDAIForCrystalAndETHS } from "./PresaleTransactions";
import { useNavigate } from "react-router-dom";
const daiLogo = require("../assets/dai-logo.png");

export default function Option1() {
    const [daiAmount, setDaiAmount] = useState(1.00);
    const [daiBalance, setDaiBalance] = useState("0");
    const [allowance, setAllowance] = useState(0);
    const navigate = useNavigate();
    const handleChange = (e) => {
        e.preventDefault();
        if(e.target.value > 900) {
            setDaiAmount(900)
        } else {
            setDaiAmount(e.target.value);
        }
      };
      useEffect(() => {
        window.ethereum.on("accountsChanged", (accounts: string[]) => {
          window.location.reload();
        });
          getDAIBalance().then(res => setDaiBalance(res));
          getDAIAllowanceForCrystalContract().then(allowance => {
            setAllowance(allowance);
            const approveBtn = document.getElementsByClassName("presale-approve")[0];
            const spinner = document.getElementById("spinner");
            if(allowance > 0 && allowance >= daiAmount) {
                if(spinner) { spinner.style.display = "none"; }
                approveBtn.textContent = "Swap";
            } else {
                approveBtn.textContent = "Approve DAI.e";
            }
        })
      })

    function swapOrStartInterval() {
        const spinner = document.getElementById("spinner");
        const approveBtn = document.getElementsByClassName("presale-approve")[0];
        if(allowance >= daiAmount) {
            if(spinner) {
                spinner.style.display = "none";
            }
            return;
        }
        if(spinner && approveBtn) {
            spinner.style.display = "block";
            approveBtn.textContent = "";
        }
        const allowanceInterval = setInterval(() => {
          getDAIAllowanceForCrystalContract().then(allowance => {
              setAllowance(allowance);
              if(allowance > 0 && allowance >= daiAmount) {
                  if(spinner) { spinner.style.display = "none"; }
                  approveBtn.textContent = "Swap";
                  clearInterval(allowanceInterval);
              }
            })
        }, 1000);
    }

  return (
    <Box id="option-1-bg">
      <PresaleHeader showDai/>
      <Box className="option-center">
        <Center className="presale-header">Option 2</Center>
        <Box className="presale-header-desc">
          This page is for Option 2 of the presale. You are able to purchase up
          to 1500 pETHS for a price of 0.60 DAI each. The first 1000 pETHS will be 
          automatically converted into a pMAXCRYSTAL, and the remaining 500 will be
          sold as pETHS. <b>You must spend at least 600 DAI for this option.</b>
        </Box>
        <Box className="option-2-swap-box">
          <Box className="presale-dai-box">
            <Box className="presale-dai-inner">
              <Box className="presale-dai-top" display="inline-block">
                  <input className="dai-input" placeholder="0.0" value={daiAmount} type="number" onChange={handleChange}></input>
                <Box marginTop="25px" display="inline-block">
                  <img src={daiLogo} width="38px" height="38px" style={{float: "left"}}></img>
                  <Text float="right" fontSize="25px">&nbsp;DAI.e</Text>
                </Box>
              </Box>
              <Box className="presale-dai-bottom">
                <Box className="dai-input-caption">${Number(daiAmount).toFixed(2)}</Box>
                <Box className="dai-input-balance">Balance: {daiBalance}</Box>
              </Box>
            </Box>
          </Box>
          <Center marginTop="20px">
                <Box borderRadius="17.5px" h="35px" w="35px" border="3px solid white">
                    <Box position="absolute" marginLeft="2px" marginTop="1px">
                        <DownArrowIcon1 />
                    </Box>
                </Box>
            </Center>
            <Box className="presale-output-box">
                <Center h="100%" fontSize="28px" w="100%">
                    {daiAmount < 600 ? 0 : 1} 
                    &nbsp;pMAXCRYSTAL
                    {daiAmount > 600 ? " + "+Number((daiAmount - 600)*(1/0.6)).toFixed(3)+" pETHS" : null}
                </Center>
            </Box>
            <Button className="presale-approve" onClick={() => {
                if(allowance >= 0 && allowance < daiAmount) {
                  approveDAIForCrystalContract((daiAmount).toString()).then(() => swapOrStartInterval());
                } else {
                  swapDAIForCrystalAndETHS((daiAmount*(1/0.6)).toString()).then(() => {
                    const daiBefore = daiBalance;
                    const interval = setInterval(() => {
                        getDAIBalance().then(res => {
                            if(res != daiBefore) {
                                setDaiBalance(res);
                                clearInterval(interval);
                            }
                        })
                    }, 1000);
                  });
                }
            }}>
                Approve DAI.e
            </Button>
            <svg id="spinner" className="approve-spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
            <Center className="add-pETHS-to-wallet">
                <a onClick={() => addpMAXCRYSTALToWallet()}>
                  Add pMAXCRYSTAL to Wallet
                </a>
            </Center>
            <Center className="add-pETHS-to-wallet" marginTop="10px">
                <a onClick={() => addpETHSToWallet()}>
                    Add pETHS to Wallet
                </a>
            </Center>
        </Box>
        <Center>
        <Button minW="300px" marginTop="50px" color="black" bgColor="#af79c7 !important" _hover={{ bg: "#d197ce !important" }}
        onClick={() => navigate("/presale/option1")}>Option 1</Button>
        </Center>
      </Box>
    </Box>
  );
}
