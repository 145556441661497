import { ethers } from "ethers";
import CONSTANTS from "../Constants.json";
import ETHSTokenABI from "../ABIS/ETHSTokenABI.json";
import RewardManagerABI from "../ABIS/RewardManagerABI.json";
import ETHSLotteryABI from "../ABIS/ETHSLotteryABI.json";
import { preTransact } from "../Transactions";
import { getAddress } from "../ConnectAccount";

function getLotteryTokenContract(provider) {
  return new ethers.Contract(CONSTANTS.LotteryAddress, ETHSLotteryABI, provider);
}

function getRewardManagerContract(provider) {
  return new ethers.Contract(CONSTANTS.RewardsManagerAddress, RewardManagerABI, provider);
}

const precisionConstant = 0.0001;

export async function getTotalLockedAndEntryPrice() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getLotteryTokenContract(signer);
    const [totalLocked, entryPrice] = await contract.getTotalLockedAndEntryPrice(address);
    return [((totalLocked/1e18)).toString(), ((entryPrice/1e18)+precisionConstant).toString()];
}

export async function playLottery() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getLotteryTokenContract(signer);
    const amount = ethers.utils.parseEther((Number((await getTotalLockedAndEntryPrice())[1])+precisionConstant).toString());
    const tx = await contract.enterLottery({value: amount});
    return await tx.wait();
}

export async function watchLottery() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getLotteryTokenContract(signer);
    return await new Promise(async (resolve, reject) => {
        contract.on("LotteryEvent", async (address, avaxAmount, ethsAmount) => {
            if(String(address).toLowerCase() == String(await getAddress()).toLowerCase()) {
                resolve(Number(ethsAmount/1e18));
            }
        })
    });
}

export async function getLotteryCooldown() {
    const [provider, address] = await preTransact();
    const signer = provider.getSigner();
    const contract = getLotteryTokenContract(signer);
    return await contract.getLotteryCooldown();
}