import {
  Box,
  Button,
  Center,
  SimpleGrid,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  FormControl,
  Input,
  ModalFooter,
  FormLabel,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  VStack,
  useInterval,
} from "@chakra-ui/react";
import "./ethervault.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  getBalance,
  getStakeCooldown,
  getStakedAmount,
  vaultDeposit,
  vaultWithdraw,
  getClaimableAmount,
  getClaimedAmount,
  claimDividend,
} from "../../Transactions";
import { useCallback, useEffect, useRef, useState } from "react";

export function convertTime(remainingTime) {
  const days = Math.floor(remainingTime / 3600 / 24);
  const hours = Math.floor((remainingTime - days * 24 * 3600) / 3600);
  var minutes = Math.floor(
    (remainingTime - (days * 24 * 3600 + hours * 3600)) / 60
  );

  if (days === 0 && hours === 0 && minutes == 0 && remainingTime > 0) {
    minutes = 1;
  }

  return `Remaining: ${days} days: ${hours} hours ${minutes} minutes`;
}

function Countdown({period, showTimer}) {
  const [timeLeft, setTimeLeft] = useState(0);
  useEffect(() => {
    if(period > 0) {
      setTimeLeft(period);
    }
  }, [period]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(timeLeft - 10);
      if(timeLeft <= 0) {
        setTimeLeft(0);
        clearInterval(interval);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    }
  });

  return (
    <>
      {showTimer ? (
        <CountdownCircleTimer
          isPlaying
          duration={4 * 3600 * 24}
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[0, 0, 0, 0]}
          size={330}
          strokeLinecap="butt"
          initialRemainingTime={period}
        >
          {({ remainingTime }) => (
            <Box marginTop="370px">{convertTime(timeLeft)}</Box>
          )}
        </CountdownCircleTimer>
      ) : null}
    </>
  );
}

export function EtherVault({balance, stakedAmount, claimedAmount, claimableAmount, period, showTimer}) {
  const {
    isOpen: isDepositOpen,
    onOpen: onDepositOpen,
    onClose: onDepositClose,
  } = useDisclosure();
  const {
    isOpen: isWithdrawOpen,
    onOpen: onWithdrawOpen,
    onClose: onWithdrawClose,
  } = useDisclosure();
  const {
    isOpen: isClaimOpen,
    onOpen: onClaimOpen,
    onClose: onClaimClose,
  } = useDisclosure();

  const depositRef = useRef<HTMLInputElement>(null);

  const withdrawRef = useRef<HTMLInputElement>(null);

  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  return (
    <div id="ether-vault-id">
      <div className="vault-title">Ether Vault</div>
      <Text marginTop="5px">
        Stake your ETHS in the Ether Vault to receive Wrapped Ethereum (wETH.e)
        reflections. There is a minimum stake of 5 ETHS to earn rewards, and <b>there is a 28% tax on all stakes.</b>
        &nbsp;There are no taxes on claiming or unstaking.
      </Text>
      <Box
        color="black"
        marginTop="5px"
        className="vault-button-ctr"
        whiteSpace="nowrap"
      >
        <Button margin="5px" onClick={onDepositOpen}>
          Stake ETHS
        </Button>
        <Button margin="5px" onClick={onWithdrawOpen}>
          Unstake ETHS
        </Button>
        <Button className="claim-btn" margin="5px" onClick={onClaimOpen}>
          Claim Rewards
        </Button>
      </Box>
      <Modal isOpen={isDepositOpen} onClose={onDepositClose}>
        <ModalOverlay />
        <ModalContent
          color="#E5DFD8"
          border="1px solid white"
          bgColor="rgba(22, 12, 34)"
          marginTop="400px"
        >
          <ModalHeader>Enter an amount to stake:</ModalHeader>
          <ModalBody pb={6}>
            <FormControl mt={4}>
              <FormLabel>Amount (Current balance: {Number(balance).toFixed(4)}):</FormLabel>
              <Input
                color="black"
                backgroundColor="whiteAlpha.900"
                fontWeight="500"
                width="80%"
                defaultValue={0}
                ref={depositRef}
              />
              <Button
                marginLeft="15px"
                width="60px"
                bgColor="lightblue !important"
                position="absolute"
                borderRadius="30px"
                color="black"
                onClick={() => {
                  if (depositRef && depositRef.current) {
                    depositRef.current.value = Number(balance).toString();
                  }
                }}
              >
                Max
              </Button>
            </FormControl>
          </ModalBody>
          <Center color="red.300" marginLeft="30px" marginRight="30px" marginBottom="10px">Directly Staking is taxed 28%.</Center>
          <ModalFooter>
            <Button
              color="black"
              bgColor="#af79c7 !important"
              _hover={{ bg: "#d197ce !important" }}
              type="submit"
              mr={3}
              onClick={() => {
                if (depositRef && depositRef.current) {
                  vaultDeposit(depositRef.current.value).then(() => {
                    onDepositClose();
                  });
                }
              }}
            >
              Stake
            </Button>
            <Button
              color="black"
              onClick={() => {
                onDepositClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isWithdrawOpen} onClose={onWithdrawClose}>
        <ModalOverlay />
        <ModalContent
          color="#E5DFD8"
          border="1px solid white"
          bgColor="rgba(22, 12, 34)"
          marginTop="400px"
        >
          <ModalHeader>Enter an amount to unstake:</ModalHeader>
          <ModalBody pb={6}>
            <FormControl mt={4}>
              <FormLabel>Amount (Current stake: {Number(stakedAmount).toFixed(4)}):</FormLabel>
              <Input
                color="black"
                backgroundColor="whiteAlpha.900"
                fontWeight="500"
                width="80%"
                defaultValue={0}
                ref={withdrawRef}
              />
              <Button
                marginLeft="15px"
                width="60px"
                bgColor="lightblue !important"
                position="absolute"
                borderRadius="30px"
                color="black"
                onClick={() => {
                  if (withdrawRef && withdrawRef.current) {
                    withdrawRef.current.value = Number(stakedAmount).toString();
                  }
                }}
              >
                Max
              </Button>
            </FormControl>
          </ModalBody>
          {period > 0 ? <Center color="red.300" marginLeft="30px" marginRight="30px">Your vault cooldown must be at zero to unstake.</Center> : null}
          <Center marginLeft="30px" marginRight="30px">If the transaction doesn't go through, claim your pending wETH rewards first then try again.</Center>
          <ModalFooter>
            <Button
              color="black"
              bgColor="#af79c7 !important"
              _hover={{ bg: "#d197ce !important" }}
              type="submit"
              mr={3}
              onClick={() => {
                if (withdrawRef && withdrawRef.current) {
                  vaultWithdraw(withdrawRef.current.value).then(() => {
                    onWithdrawClose();
                  });
                }
              }}
            >
              Unstake
            </Button>
            <Button
              color="black"
              onClick={() => {
                onWithdrawClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isClaimOpen} onClose={onClaimClose}>
        <ModalOverlay />
        <ModalContent
          color="#E5DFD8"
          border="1px solid white"
          bgColor="rgba(22, 12, 34)"
          marginTop="400px"
        >
          <ModalHeader marginBottom="10px">Select an option:</ModalHeader>
          <ModalBody pb={6}>
            <Center>
              <VStack spacing={3}>
                <Button
                  color="black"
                  bgColor="#af79c7 !important"
                  _hover={{ bg: "#d197ce !important" }}
                  onClick={() => claimDividend(0).then(() => onClaimClose())}
                >
                  Claim as wETH.e
                </Button>
                <Button
                  color="black"
                  bgColor="#af79c7 !important"
                  _hover={{ bg: "#d197ce !important" }}
                  onClick={() => claimDividend(2).then(() => onClaimClose())}
                >
                  Claim as ETHS
                </Button>
                <Button
                  color="black"
                  bgColor="#af79c7 !important"
                  _hover={{ bg: "#d197ce !important" }}
                  onClick={() => claimDividend(1).then(() => onClaimClose())}
                >
                  Compound into Vault
                </Button>
              </VStack>
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button
              color="black"
              onClick={() => {
                onClaimClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div className="container">
        <div className="countdown">
          <Countdown period={period} showTimer={showTimer}/>
        </div>
        <div className="wrapper">
          <div className="pyramid">
            <div className="square">
              <div className="triangle"></div>
              <div className="triangle"></div>
              <div className="triangle"></div>
              <div className="triangle"></div>
            </div>
          </div>

          <div className="pyramid inverse">
            <div className="square">
              <div className="triangle"></div>
              <div className="triangle"></div>
              <div className="triangle"></div>
              <div className="triangle"></div>
            </div>
          </div>
        </div>
        <Box
          position="absolute"
          bottom="-200px"
          marginLeft="-20px"
          padding="20px"
          w="100%"
          className="vault-footer"
        >
            <Center padding="5px" whiteSpace="nowrap">
              Staked ETHS<br />
              {stakedAmount}
            </Center>
            <Center padding="5px" whiteSpace="nowrap">
              Claimed <br />
              {claimedAmount} wETH.e
            </Center>
            <Center padding="5px" whiteSpace="nowrap">
              Claimable <br />
              {claimableAmount} wETH.e
            </Center>
        </Box>
      </div>
    </div>
  );
}
